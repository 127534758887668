import styled, { css } from 'styled-components';

interface Props {
  $isSelected?: boolean;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;

  @media (max-width: 1495px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.div<Props>`
  > div.firstTitle {
    display: flex;
    flex-direction: row;
    align-items: center;

    > button {
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  & > div {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;

    span.title {
      display: inline-block;
      font-weight: 700;
      margin-left: 16px;
    }

    > span.code {
      margin-left: 8px;
    }

    > span.ccb {
      margin-left: 45px;
    }
  }
`;

export const TabContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;

  padding: 16px 24px 24px 24px;
`;

export const MiniTabContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;

  padding: 16px 24px 24px 24px;
`;

export const TabTitleHeader = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray24};
`;

export const TabHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const TabHeaderInnerContainer = styled.div`
  height: 25px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    text-decoration: none;
  }
`;

export const TabClientName = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.color.statusNew};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-decoration: underline;
  cursor: pointer;
`;

export const TabBox = styled.div`
  /* background-color: ${({ theme }) => theme.color.grayBackground}; */
  padding: 0 4px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */

  display: flex;
  align-items: flex-start;
  gap: 24px;

  border-bottom: 1px solid var(--gray-light-3, #e5e7e7);
`;

export const Tab = styled.button<Props>`
  background-color: transparent;
  padding: 8px;
  border: none;

  border-bottom: ${({ theme, $isSelected }) => ($isSelected ? `4px solid ${theme.color.primaryColor}` : 'none')};
  > span {
    font-style: normal;
    font-weight: ${({ $isSelected }) => ($isSelected ? '700' : '400')};
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.color.gray21 : theme.color.gray18)};
  }
`;

export const CardsContainer = styled.div`
  margin: 0 auto;
  max-width: 70%;
  margin-top: 21px;
  margin-bottom: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 21px;

  div {
    margin: 0;
  }
`;

export const CardText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};

  display: inline-block;
  margin-top: 8px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > span.credit {
    margin-left: 8px;
  }

  & > div.status {
    margin-top: 8px;
  }
`;

export const GuarantorCount = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.color.borderGray};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 8px !important;
  margin-left: 8px !important;

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
  }
`;

export const InputContent = styled.div`
  width: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  > label + label {
    margin-top: 35px;
  }
`;

export const IconBox = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.color.verylightBlue};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  & > svg {
    > path {
      fill: transparent;
    }
  }
`;

export const FormContainer = styled.form`
  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 306px;
    min-height: 53px;
  }
  label + label {
    margin-top: 16px;
  }
`;

export const TabContent = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  & > button.cancel {
    background-color: ${({ theme }) => theme.color.rejected};
    border-color: ${({ theme }) => theme.color.rejected};
  }

  @media (max-width: 1495px) {
    width: 100%;
    display: flex;
    justify-content: right;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 70%;
  margin: 0 auto;
  padding-left: 50px;

  column-gap: 10px;
`;

interface StyledButtonProps {
  $height?: number;
  $width?: string;
  $maxWidth?: string;
  $isInvertColor?: boolean;
  $freeContent?: boolean;
  $justifyContent?: 'center' | 'space-between';
}

export const CancelButton = styled.button<StyledButtonProps>`
  height: ${({ $height }) => `${$height}px`};
  width: ${({ $width }) => $width};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyContent }) => $justifyContent ?? 'space-between'};
  padding: ${({ $freeContent }) => ($freeContent ? '0px' : '12px 24px')};
  max-width: ${({ $maxWidth }) => $maxWidth};
  background-color: ${({ theme, $isInvertColor }) => ($isInvertColor ? theme.color.grayBackground : theme.color.rejected)};
  border-radius: 24px;
  border: ${({ theme, $isInvertColor }) => ($isInvertColor ? `1.5px solid ${theme.color.rejected}` : `1px solid ${theme.color.rejected}`)};
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme, $isInvertColor }) => ($isInvertColor ? theme.color.rejected : '#ffffff')};

  :disabled {
    opacity: 0.7;
  }

  img {
    margin-right: 12px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 575.98px) {
    height: ${({ $height }) => ($height != null ? `${$height - 3}px` : $height)};
    font-size: 14px;
  }

  > div {
    height: max-content !important;
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e5e7e7;
  background: #fff;
  width: 70%;
  margin: auto;
  margin-top: 15px;
  height: 65px;
`;

export const InfoButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.color.primaryColor};
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const SelfieContainer = styled.div`
  width: 100%;
  min-height: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > .button-container {
    display: flex;
    justify-content: space-between;
    width: 420px;
  }
`;

export const LivenessContainer = styled.div`
  width: 100%;
  min-height: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 24px;
`;

interface ContentContainerProp {
  $custom?: string;
}

export const ContentContainer = styled.div<ContentContainerProp>`
  min-height: 63px;
  position: relative;
  align-self: flex-start;
  margin-top: 24px;
  ${props => props.$custom}
`;

export const EmptyContainer = styled.div`
  display: flex;
  height: 44px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-light-2, #ccc);
`;

export const StyleTextModalContainer = styled.div`
  max-width: 546px;

  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray19};

  @media (max-width: 575.98px) {
    font-size: 14px;
  }
`;

export const StyledSubtitle = styled.div`
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
`;
