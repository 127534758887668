import { AxiosResponse } from 'axios';
import { Contract } from 'model/financing';
import { api } from './api';
import IContractDetails from 'model/contract-details';
import { IConfirmData } from 'model/confirm-data-signature';
import IOcr from 'model/ocr';
import FaceMatch from 'model/enums/face-match';
import { ILiveness, ILivenessRepose } from 'model/liveness';

const contractApi = () => {
  const createFaceMatch = (data: FaceMatch): Promise<AxiosResponse<FaceMatch>> => {
    return api.post<FaceMatch>('/person-signature/create-face-match', data);
  };

  const getContractById = (contractId: number): Promise<AxiosResponse<Contract>> => {
    return api.get<Contract>(`/contracts/${contractId}`);
  };

  const getContract = (signatureKey: string): Promise<AxiosResponse<IContractDetails>> => {
    return api.get<IContractDetails>(`/person-signature/signature-key/${signatureKey}`);
  };

  const resendEmail = (contractId: number): Promise<AxiosResponse<Contract>> => {
    return api.post<Contract>(`/contracts/${contractId}/resend-email`);
  };

  const sendOcr = (data: IOcr): Promise<AxiosResponse<IOcr>> => {
    return api.post<IOcr>(`/person-signature/create-ocr`, data);
  };

  const confirmPersonSignatureData = (data: IConfirmData): Promise<AxiosResponse<IConfirmData>> => {
    return api.patch<IConfirmData>('/person-signature/confirm-data', data);
  };

  const finalizeContractSigning = (personSignatureId: number): Promise<AxiosResponse<IConfirmData>> => {
    return api.patch<IConfirmData>(`/person-signature/finish/${personSignatureId}`);
  };

  const sendLiveness = (contractId: number, data: ILiveness): Promise<AxiosResponse<ILivenessRepose>> => {
    return api.post<ILivenessRepose>(`/person-signature/${contractId}/create-liveness`, data);
  };

  return {
    createFaceMatch,
    getContractById,
    resendEmail,
    getContract,
    confirmPersonSignatureData,
    finalizeContractSigning,
    sendOcr,
    sendLiveness,
  };
};

export default contractApi();
