import contractApi from 'api/contract-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { Contract } from 'model/financing';
import IContractDetails from 'model/contract-details';
import { IConfirmData } from 'model/confirm-data-signature';
import IOcr from 'model/ocr';
import FaceMatch from 'model/enums/face-match';
import { ILiveness, ILivenessRepose } from 'model/liveness';

const contractService = () => {
  const createFaceMatch = async (data: FaceMatch) => {
    try {
      const result: AxiosResponse<IContractDetails> = await contractApi.createFaceMatch(data);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getContractById = async (contractId: number): Promise<Contract> => {
    try {
      const result: AxiosResponse<Contract> = await contractApi.getContractById(contractId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };
  const getContract = async (signatureKey: string) => {
    try {
      const result: AxiosResponse<IContractDetails> = await contractApi.getContract(signatureKey);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };
  const sendPersonDataConfirmation = async (data: IConfirmData) => {
    try {
      const result: AxiosResponse<IConfirmData> = await contractApi.confirmPersonSignatureData(data);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const finalizeContractSigning = async (personSignatureId: number) => {
    try {
      const result: AxiosResponse<IConfirmData> = await contractApi.finalizeContractSigning(personSignatureId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendOcr = async (data: IOcr) => {
    try {
      const result: AxiosResponse<IOcr> = await contractApi.sendOcr(data);
      if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;

      return Promise.reject(response);
    }
  };

  const sendLiveness = async (contractId: number, data: ILiveness) => {
    try {
      const result: AxiosResponse<ILivenessRepose> = await contractApi.sendLiveness(contractId, data);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;

      return Promise.reject(response);
    }
  };

  return {
    createFaceMatch,
    getContractById,
    getContract,
    sendPersonDataConfirmation,
    finalizeContractSigning,
    sendOcr,
    sendLiveness,
  };
};

export default contractService();
