import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const LandingPage = () => {
  const history = useHistory();
  useEffect(() => {
    history.push('/admin/login');
  });
  return <></>;
};

export default LandingPage;
