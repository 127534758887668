import Popup from 'reactjs-popup';
import styled from 'styled-components';

interface Props {
  $large?: boolean;
}

interface StyledPopupProps {
  children: (close: any) => JSX.Element;
}

export const StyledPopUp = styled(Popup)<StyledPopupProps>`
  &-overlay {
    background: ${({ theme }) => theme.color.shadow};
  }
  &-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledContainer = styled.div<Props>`
  width: ${({ $large }) => ($large ? '630px' : 'max-content')};
  max-width: 90%;
  padding: 30px 30px 60px 30px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const StyledXIconContainer = styled.div`
  text-align: right;
`;
