export enum PersonSignatureStatusTypes {
  AWAITING_SUBMISSION_FOR_SIGNATURE = 'AWAITING_SUBMISSION_FOR_SIGNATURE',
  SENT_FOR_SIGNATURE = 'SENT_FOR_SIGNATURE',
  ERROR_ANTI_FRAUD_OCR = 'ERROR_ANTI_FRAUD_OCR',
  ERROR_ANTI_FRAUD_FACE_MATCH = 'ERROR_ANTI_FRAUD_FACE_MATCH',
  ERROR_ANTI_FRAUD_LIVENESS = 'ERROR_ANTI_FRAUD_LIVENESS',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  AWAITING_SIGNED_CONTRACT = 'AWAITING_SIGNED_CONTRACT',
}
