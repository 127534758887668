import { StyledInnerContainer, StyledPaintSvg } from './styles';
import { ReactComponent as ExpiredSubscriptionSvg } from 'images/expired-subscription.svg';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';

const ExpiredLink = ({ t }: WithTranslation) => {
  return (
    <>
      <MobileHeader />
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.expiredLink.title')}</h1>
        <span>{t('contract.waitingStep.expiredLink.subtitle')}</span>
        <span>{t('contract.waitingStep.expiredLink.itsNotCase')}</span>
        <StyledPaintSvg className="expired-subscription">
          <ExpiredSubscriptionSvg />
        </StyledPaintSvg>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(ExpiredLink);
