import { StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import ContractContext from 'context/contract';
import { useHistory } from 'react-router-dom';
import MobileHeader from 'components/mobile-header';

import { WaitRegistrationValidationSvg } from 'images/wait-registration-validation';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { RoutePath } from 'model/enums/route-path';
import { LoadingIcon } from 'images/loadingIcon/loadingIcon';

const DocumentAnalysis = ({ t }: WithTranslation) => {
  const { color } = useTheme();
  const history = useHistory();

  const { signatureKey } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
    }
  }, []);

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.documentAnalysis.title')}</h1>
        <span>{t('contract.waitingStep.documentAnalysis.subtitle')}</span>
        <div className="animationAndSvg">
          <WaitRegistrationValidationSvg />
          <LoadingIcon className="spin" />
        </div>
        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(DocumentAnalysis);
