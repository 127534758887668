enum SystemStepCategory {
  SIMULATOR = 'SIMULATOR',
  GUARANTORS = 'GUARANTORS',
  BANK_ACCOUNT_DATA = 'BANK_ACCOUNT_DATA',
  FRAUD_ANALYSIS = 'FRAUD_ANALYSIS',
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
  DOC_FRAUD_ANALYSIS = 'DOC_FRAUD_ANALYSIS',
  FINANCING_ANALYSIS = 'FINANCING_ANALYSIS',
  SCORE_ANALYSIS = 'SCORE_ANALYSIS',
  VEHICLE = 'VEHICLE',
  PROPERTY = 'PROPERTY',
  FRAUD_ANALYSIS_RATINGS = 'FRAUD_ANALYSIS_RATINGS',
  FRAUD_ANALYSIS_INSIGHTS = 'FRAUD_ANALYSIS_INSIGHTS',
  FRAUD_ANALYSIS_SCORE = 'FRAUD_ANALYSIS_SCORE',
  FRAUD_ANALYSIS_2FA = 'FRAUD_ANALYSIS_2FA',
  FRAUD_ANALYSIS_BIOMETRY = 'FRAUD_ANALYSIS_BIOMETRY',
  FRAUD_ANALYSIS_SELFIE = 'FRAUD_ANALYSIS_SELFIE',
  CREDIT_CARD = 'CREDIT_CARD',
  PASSWORD = 'PASSWORD',
  PERSONAL_DATA = 'PERSONAL_DATA',
  PERSONAL_DATA_TOP_ONE = 'PERSONAL_DATA_TOP_ONE',
  ADDRESS = 'ADDRESS',
  ADDRESS_TOP_ONE = 'ADDRESS_TOP_ONE',
  ADDRESS_MULTTIPLO = 'ADDRESS_MULTTIPLO',
  INCOME = 'INCOME',
  INCOME_TOP_ONE = 'INCOME_TOP_ONE',
  SELFIE = 'SELFIE',
  PHYSICAL_PERSON_DOCUMENTS = 'PHYSICAL_PERSON_DOCUMENTS',
  LOADING = 'LOADING',
  PHYSICAL_PERSON_DOCUMENTS_HCRED = 'PHYSICAL_PERSON_DOCUMENTS_HCRED',
  SCORE_ANALYSIS_DTI = 'SCORE_ANALYSIS_DTI',
  PERSONAL_DATA_MULTTIPLO = 'PERSONAL_DATA_MULTTIPLO',
  PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT = 'PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT',
  CONTRACT_ACCOUNT_ACCEPT = 'CONTRACT_ACCOUNT_ACCEPT',
  PROCEDURE_ANALYSIS = 'PROCEDURE_ANALYSIS',
  BANK_ACCOUNT_DATA_MULTTIPLO = 'BANK_ACCOUNT_DATA_MULTTIPLO',
  CONTRACT_INTERNAL = 'CONTRACT_INTERNAL',
  PAYMENT_MULTTIPLO_CLINIC = 'PAYMENT_MULTTIPLO_CLINIC',
  PAYMENT_MULTTIPLO_REFUND = 'PAYMENT_MULTTIPLO_REFUND',
  PERSONAL_DATA_MONJUA = 'PERSONAL_DATA_MONJUA',
  ADDRESS_MONJUA = 'ADDRESS_MONJUA',
  INCOME_MONJUA = 'INCOME_MONJUA',
  REFERENCES_MONJUA = 'REFERENCES_MONJUA',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  PARTNER_DATA = 'PARTNER_DATA',
  GENERAL_DATA = 'GENERAL_DATA',
  PARTNER_RELATIONS = 'PARTNER_RELATIONS',
  PATRIMONIAL_SITUATION = 'PATRIMONIAL_SITUATION',
  MY_COMPANY = 'MY_COMPANY',
  LEGAL_PERSON_DOCUMENTS = 'LEGAL_PERSON_DOCUMENTS',
  ADDRESS_ZM = 'ADDRESS_ZM',
  LIVENESS = 'LIVENESS',
  FACE_MATCH = 'FACE_MATCH',
  OCR = 'OCR',
  PERSONAL_DATA_ZM = 'PERSONAL_DATA_ZM',
  SIGNATURE_DATA = 'SIGNATURE_DATA',
  INSURANCE = 'INSURANCE',
  ADDRESS_EVOLVE = 'ADDRESS_EVOLVE',
  INCOME_EVOLVE = 'INCOME_EVOLVE',
  HISTORIC = 'HISTORIC',
  INCOME_ZM = 'INCOME_ZM',
  COMMENTS = 'COMMENTS',
}

export default SystemStepCategory;
