import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledInnerContainer = styled.div`
  .mobile-button--gofoward {
    border-radius: 8px;
    background-color: #0f62fe;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    box-shadow: 0px -1px 9px 0px rgba(0, 0, 0, 0.21);
  }
`;

export const LivenessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--gray-light-3, #e5e7e7);
  background: var(--gray-light-5, #fafafa);
`;
