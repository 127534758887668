import { AxiosResponse } from 'axios';
import { ORGANIZATION_TYPE } from 'config/constants';
import {
  ContractDashboard,
  FinancingDashboard,
  InitialDashboard,
  PaymentDashboard,
  ProcedureDashboard,
  SignatureDashboard,
} from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { OrganizationType } from 'model/enums/organization-type';
import StepType from 'model/enums/step-type';
import { PageableResponse } from 'model/pageable';
import { api } from './api';
import { Ordertype } from 'model/enums/order-type';
import { Client } from 'model/client';

export const dashboard = () => {
  const baseUrl = '/dashboard';
  const getInitialDashboardInfos = (financingType?: StepType): Promise<AxiosResponse<InitialDashboard>> => {
    return api.get<InitialDashboard>(`${baseUrl}${financingType ? `?financingType=${financingType}` : ''}`);
  };
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  const getDashboardClients = (
    name?: string,
    size?: number,
    page?: number,
    registrationStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<Client>>> => {
    return api.get<PageableResponse<Client>>(
      `${baseUrl}/clients?size=${size ?? 5}&page=${page ?? 0}${subsidiaryName && `&organizationSubsidiary.name=${subsidiaryName}`}${
        isPhysicalPerson
          ? `&physicalPerson.cpf=${name ?? ''}&physicalPerson.name=${name ?? ''}`
          : `&legalPerson.corporateName=${name ?? ''}`
      }${
        registrationStatus && registrationStatus.length > 0
          ? registrationStatus.map(status => `&registrationStatus=contains(${status})`).join('')
          : ''
      }&sort=createdDate,${!order ? Ordertype.DESC : Ordertype.ASC}${
        createdDates?.gte ? `&createdDate=gte(${createdDates.gte}T00:00:00)` : ''
      }${createdDates?.lte ? `&createdDate=and(lte(${createdDates.lte}T24:00:00))` : ''}`
    );
  };

  const getDashboardFinancings = (
    name?: string,
    size?: number,
    page?: number,
    financingStatus?: string[],
    createdDates?: CreatedDates,
    identification?: string,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<FinancingDashboard>>> => {
    return api.get<PageableResponse<FinancingDashboard>>(
      `${baseUrl}/financings/personal-credit?size=${size ?? 5}&client.physicalPerson.cpf=${name ?? ''}&page=${page ?? 0}${
        subsidiaryName && `&organizationSubsidiary.name=${subsidiaryName}`
      }${isPhysicalPerson ? `&client.physicalPerson.name=${name ?? ''}` : `&client.legalPerson.corporateName=${name ?? ''}`}${
        financingStatus && financingStatus.length > 0 ? financingStatus.map(status => `&financingStatus=contains(${status})`).join('') : ''
      }&sort=lastModifiedDate,${!order ? Ordertype.DESC : Ordertype.ASC}${
        createdDates?.gte ? `&lastModifiedDate=gte(${createdDates.gte}T00:00:00)` : ''
      }${createdDates?.lte ? `&lastModifiedDate=and(lte(${createdDates.lte}T24:00:00))` : ''}${
        identification ? `&identification=${identification}` : ''
      }`
    );
  };

  const getDashboardFinancingsHcred = (
    name?: string,
    size?: number,
    page?: number,
    financingStatus?: string[],
    createdDates?: CreatedDates,
    identification?: string,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<FinancingDashboard>>> => {
    return api.get<PageableResponse<FinancingDashboard>>(
      `${baseUrl}/financings/personal-credit?size=${size ?? 5}&client.physicalPerson.cpf=${name ?? ''}&page=${page ?? 0}${
        subsidiaryName ? `&organizationSubsidiary.name=${subsidiaryName}` : ''
      }${isPhysicalPerson ? `&client.physicalPerson.name=${name ?? ''}` : `&client.legalPerson.corporateName=${name ?? ''}`}${
        financingStatus && financingStatus.length > 0
          ? financingStatus.map(status => `&financingHcredStatus=contains(${status})`).join('')
          : ''
      }&sort=lastModifiedDate,${!order ? Ordertype.DESC : Ordertype.ASC}${
        createdDates?.gte ? `&lastModifiedDate=gte(${createdDates.gte}T00:00:00)` : ''
      }${createdDates?.lte ? `&lastModifiedDate=and(lte(${createdDates.lte}T24:00:00))` : ''}${
        identification ? `&identification=${identification}` : ''
      }`
    );
  };

  const getDashboardContracts = (
    name?: string,
    size?: number,
    page?: number,
    contractStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<ContractDashboard>>> => {
    return api.get<PageableResponse<ContractDashboard>>(
      `${baseUrl}/contracts?size=${size ?? 5}${
        isPhysicalPerson
          ? `&financing.client.physicalPerson.cpf=${name ?? ''}`
          : `&financing.client.legalPerson.corporateName=${name ?? ''}`
      }&page=${page ?? 0}${subsidiaryName && `&organizationSubsidiary.name=${subsidiaryName}`}&financing.client.physicalPerson.name=${
        name ?? ''
      }${
        contractStatus && contractStatus.length > 0 ? contractStatus.map(status => `&status=contains(${status})`).join('') : ''
      }&sort=createdDate,${!order ? Ordertype.DESC : Ordertype.ASC}${
        createdDates?.gte ? `&createdDate=gte(${createdDates.gte}T00:00:00)` : ''
      }${createdDates?.lte ? `&createdDate=and(lte(${createdDates.lte}T24:00:00))` : ''}`
    );
  };

  const getDashboardPayments = (
    name?: string,
    size?: number,
    page?: number,
    paymentStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<PaymentDashboard>>> => {
    return api.get<PageableResponse<PaymentDashboard>>(
      `${baseUrl}/payments?size=${size ?? 5}&page=${page ?? 0}${
        subsidiaryName && `&organizationSubsidiary.name=${subsidiaryName}`
      }&financing.client.physicalPerson.cpf=${name ?? ''}${
        isPhysicalPerson
          ? `&financing.client.physicalPerson.name=${name ?? ''}`
          : `&financing.client.legalPerson.corporateName=${name ?? ''}`
      }${
        paymentStatus && paymentStatus.length > 0 ? paymentStatus.map(status => `&status=contains(${status})`).join('') : ''
      }&sort=createdDate,${!order ? Ordertype.DESC : Ordertype.ASC}${
        createdDates?.gte ? `&createdDate=gte(${createdDates.gte}T00:00:00)` : ''
      }${createdDates?.lte ? `&createdDate=and(lte(${createdDates.lte}T24:00:00))` : ''}`
    );
  };

  const getDashboardProcedures = (
    name?: string,
    size?: number,
    page?: number,
    paymentStatus?: string[],
    createdDates?: CreatedDates,
    order?: boolean,
    subsidiaryName?: string
  ): Promise<AxiosResponse<PageableResponse<ProcedureDashboard>>> => {
    return api.get<PageableResponse<ProcedureDashboard>>(
      `${baseUrl}/financings/procedures?size=${size ?? 5}&page=${page ?? 0}${
        subsidiaryName && `&organizationSubsidiary.name=${subsidiaryName}`
      }${
        isPhysicalPerson && name
          ? `&client.physicalPerson.name=${name ?? ''}`
          : name
            ? `&client.legalPerson.corporateName=${name ?? ''}`
            : ''
      }${
        paymentStatus && paymentStatus.length > 0 ? paymentStatus.map(status => `&financingStatus=contains(${status})`).join('') : ''
      }&sort=createdDate,${!order ? Ordertype.DESC : Ordertype.ASC}${
        createdDates?.gte ? `&createdDate=gte(${createdDates.gte}T00:00:00)` : ''
      }${createdDates?.lte ? `&createdDate=and(lte(${createdDates.lte}T24:00:00))` : ''}`
    );
  };

  const getDashboardSigantures = (
    searchName?: string,
    size?: number,
    page?: number,
    registrationStatus?: string[],
    createdDates?: CreatedDates,
    sort?: string,
    subsidiaryName?: string,
    searchOption?: string
  ): Promise<AxiosResponse<PageableResponse<SignatureDashboard>>> => {
    return api.get<PageableResponse<SignatureDashboard>>(
      `${baseUrl}/signatures?size=${size ?? 5}&page=${page ?? 0}${subsidiaryName ? `&organizationSubsidiary.name=${subsidiaryName}` : ''}${
        isPhysicalPerson
          ? `&client.physicalPerson.${searchOption}=contains(${searchName ?? ''})`
          : `&legalPerson.${searchOption}=contains(${searchName ?? ''})`
      }${
        registrationStatus && registrationStatus.length > 0
          ? registrationStatus.map(status => `&personSignatureStatus=contains(${status})`).join('')
          : ''
      }&sort=contractSignatory.contractSign.sendToSignDate,${sort}${
        createdDates?.gte ? `&contractSignatory.contractSign.sendToSignDate=gte(${createdDates.gte})` : ''
      }${createdDates?.lte ? `&contractSignatory.contractSign.sendToSignDate=and(lte(${createdDates.lte}))` : ''}`
    );
  };

  return {
    getInitialDashboardInfos,
    getDashboardClients,
    getDashboardFinancings,
    getDashboardContracts,
    getDashboardPayments,
    getDashboardProcedures,
    getDashboardFinancingsHcred,
    getDashboardSigantures,
  };
};

export default dashboard();
