import { StyledInnerContainer, StyledPaintSvg } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import ContractContext from 'context/contract';
import { useContext, useEffect } from 'react';
import contractService from 'services/contract-service';
import { RoutePath } from 'model/enums/route-path';
import { ReactComponent as FilesFolderFileSortManSvg } from 'images/files-folder-file-sort-man.svg';

const OperationSucceed = ({ t }: WithTranslation) => {
  const history = useHistory();
  const { personSignatureId } = useContext(ContractContext);

  useEffect(() => {
    if (personSignatureId != null) {
      contractService.finalizeContractSigning(personSignatureId).catch(() => history.push(RoutePath.ERROR_ANALYSIS));
    } else {
      history.push(RoutePath.ERROR_ANALYSIS);
    }
  }, [personSignatureId]);

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.operationSucceed.title')}</h1>
        <span>{t('contract.waitingStep.operationSucceed.subtitle')}</span>
        <StyledPaintSvg className="files-folder-file-sort-man">
          <FilesFolderFileSortManSvg />
        </StyledPaintSvg>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(OperationSucceed);
