import styled from 'styled-components';

interface FilterItemProps {
  $isActive?: boolean;
}

export const OptionContainer = styled.div`
  position: relative;
`;

export const FilterItem = styled.button<FilterItemProps>`
  height: 40px;
  padding: 0 12px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background: ${({ theme }) => theme.color.white};

  border: ${({ theme, $isActive }) => ($isActive ? `1px solid ${theme.color.primaryColor}` : `1px solid ${theme.color.gray20}`)};
  border-radius: 8px;
  color: ${({ theme }) => theme.color.gray21};

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  :hover {
    border: 1px solid ${({ theme }) => theme.color.primaryColor};
  }
`;

interface CalendarContainerProps {
  $width: number;
  $filterItemOffsetLeft: number;
}

export const CalendarContainer = styled.div<CalendarContainerProps>`
  position: absolute;
  top: 40px;
  left: ${({ $filterItemOffsetLeft, $width }) => $filterItemOffsetLeft - $width / 2}px;
  z-index: 10;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 8px 0px #0000001f;
  border-radius: 8px;
  padding: 16px;

  & > div.react-calendar {
    width: 240px;
    border: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-style: normal;
    font-size: 16px;
    font-weight: 700;
    margin-left: 8px;

    abbr {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      border-radius: 4px;
      padding: 8px;
      background: none;
      border: none;
      font-size: 14px;
    }

    button.react-calendar__tile {
      padding: 10px;
    }
    div.react-calendar__navigation {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    button.react-calendar__navigation__arrow {
      font-size: 24px !important;
    }
    div.react-calendar__navigation button {
      width: 16px;
      min-width: 16px;
      border-radius: 4px;
      padding: 4px;
      background: none;
      border: none;
      font-size: 16px;
    }
    button.react-calendar__tile:hover {
      background-color: ${({ theme }) => theme.color.lightGray1};
    }

    button.react-calendar__tile--now {
      background-color: ${({ theme }) => theme.color.lightBlue};
    }

    button.react-calendar__tile--active {
      background-color: ${({ theme }) => theme.color.primaryColor};
      border-radius: 29px;
    }

    button.react-calendar__tile:disabled {
      border-radius: 0px;
    }

    button.react-calendar__tile:disabled:hover {
      background-color: transparent;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.color.rejected};
  }
  button.react-calendar__navigation__label {
    width: 139px;
    max-width: 139px;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-family: Satoshi;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    color: ${({ theme }) => theme.color.gray18};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  button {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    background-color: transparent;
    border: none;
  }

  > button.clean {
    color: ${({ theme }) => theme.color.rejected};
  }

  > button.apply {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledHr = styled.hr`
  stroke-width: 1px;
  color: ${({ theme }) => theme.color.gray20};
  stroke: var(--gray-light-3, #e5e7e7);
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
`;
