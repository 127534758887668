import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import { PageTitleSection, StyledContainer, StyledContainerInputRadio } from '../styles';
import { StyledInnerContainer, StyledCardShowContractContainer } from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import ContractContext from 'context/contract';
import { RoutePath } from 'model/enums/route-path';
import CustomButton from 'components/custom-button';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import CustomInputRadio from './components/custom-input-radio';
import { isEmpty } from 'lodash';
import { IdentityDocumentTypes } from 'model/enums/identity-document-type';

const RegisterDocument = ({ t }: WithTranslation) => {
  const history = useHistory();

  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const { documentPresignUrl, signatureKey, identityDocument, setIdentityDocument } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
    }
  }, [signatureKey, isMobile]);

  const handleConfirm = () => {
    history.push(RoutePath.DOCUMENT_SUBMISSION_INSTRUCTIONS);
  };

  return (
    <StyledContainer>
      <MobileHeader />
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(RoutePath.LETS_SIGN)} />
            <span>{t('letsSign.stepOfTotal', { step: '3' })}</span>
          </ArrowContainer>
          <p>{t('contract.registerDocument.selectDocument.title')}</p>
          <span>{t('contract.registerDocument.selectDocument.subtitle')}</span>
        </PageTitleSection>

        <StyledContainerInputRadio>
          <CustomInputRadio
            title={IdentityDocumentTypes.RG}
            text={t('contract.registerDocument.selectDocument.nationalIdentityCard')}
            handleOnClick={() => setIdentityDocument(IdentityDocumentTypes.RG)}
            checked={identityDocument === IdentityDocumentTypes.RG}
          />
          <CustomInputRadio
            title={IdentityDocumentTypes.CNH}
            text={t('contract.registerDocument.selectDocument.nationalDrivingLicense')}
            handleOnClick={() => setIdentityDocument(IdentityDocumentTypes.CNH)}
            checked={identityDocument === IdentityDocumentTypes.CNH}
          />
          <CustomInputRadio
            title={t('contract.registerDocument.selectDocument.other')}
            text={t('contract.registerDocument.selectDocument.otherDocumentTypes')}
            handleOnClick={() => setIdentityDocument(IdentityDocumentTypes.OTHER)}
            checked={identityDocument === IdentityDocumentTypes.OTHER}
          />
        </StyledContainerInputRadio>
        <CustomButton className="mobile-button--gofoward" disabled={isEmpty(identityDocument)} onClick={() => handleConfirm()}>
          {t('letsSign.comfirm')}
        </CustomButton>

        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(RegisterDocument);
