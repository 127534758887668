import { CustomSideMenu } from 'components/custom-side-menu';
import { Screens } from 'model/enums/admin-dashboard';
import StepType from 'model/enums/step-type';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import { ContractsList } from './screens/contracts';
import { CreditAnalysisList } from './screens/credit-analysis';
import { InitialDashboardScreen } from './screens/initial-screen';
import { MyTeamList } from './screens/my-team';
import { PaymentsList } from './screens/payments';
import { ProcedureList } from './screens/procedure';
import { RegistersList } from './screens/registers';
import { Container, Content, PageContainer } from './styles';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import { SignatureresList } from './screens/signatures';
import Report from 'features/admin/dashboard/screens/report';
import { BranchList } from './screens/branch';
import { MyAccountList } from './screens/my-account';

export interface Tab {
  logo?: FunctionComponentElement<SVGSVGElement>;
  label?: string;
  url?: string;
}

export const AdminDashboard = () => {
  const history = useHistory();
  const [orgStepTypes, setOrgStepTypes] = useState<StepType[] | null>([]);

  useEffect(() => {
    getStepType();
  }, []);

  useEffect(() => {
    const splitedUrl = history.location.pathname.split('/');
    const screen = splitedUrl[splitedUrl.length - 1];
    if (screen === Screens.DASHBOARD && OrganizationName.NOVOSAQUE == ORGANIZATION_NAME) {
      return history.push('/admin/assinaturas');
    }
  }, []);

  const getStepType = async () => {
    const res = await organizationSystemStepsService.getStepAdmin();
    const orgTypes = res
      .filter(
        orgType =>
          orgType.stepType === StepType.CREDIT_ANTICIPATION || orgType.stepType === StepType.CCB || orgType.stepType === StepType.PROCEDURE
      )
      .map(type => type.stepType);

    const filtered = orgTypes.filter((orgType, idx) => orgTypes.indexOf(orgType) === idx);

    if (filtered.length > 0) {
      setOrgStepTypes(filtered as StepType[]);
    }
  };

  const matchUrlPageContent = () => {
    const splitedUrl = history.location.pathname.split('/');
    const screen = splitedUrl[splitedUrl.length - 1];
    switch (screen) {
      case Screens.DASHBOARD:
        return <InitialDashboardScreen orgStepTypes={orgStepTypes} />;

      case Screens.REGISTERS:
        return <RegistersList />;

      case Screens.CREDIT_ANALYSIS:
        return <CreditAnalysisList />;

      case Screens.CONTRACTS:
        return <ContractsList />;

      case Screens.PAYMENTS:
        return <PaymentsList />;

      case Screens.MY_TEAM:
        return <MyTeamList />;

      case Screens.PROCEDURE:
        return <ProcedureList />;

      case Screens.SIGNATURES:
        return <SignatureresList />;

      case Screens.ANTECIPATION:
        return <></>;

      case Screens.REPORT:
        return <Report />;

      case Screens.BRANCH:
        return <BranchList />;

      case Screens.MY_ACCOUNT:
        return <MyAccountList />;

      default:
        return <></>;
    }
  };

  return (
    <PageContainer>
      <Container>
        <CustomSideMenu />
        <Content>{matchUrlPageContent()}</Content>
      </Container>
    </PageContainer>
  );
};
