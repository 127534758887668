import { Container } from './styles';

interface StatusProps {
  text: string;
  color: string;
}

export const Status = ({ text, color }: StatusProps) => {
  return <Container $color={color}>{text}</Container>;
};
