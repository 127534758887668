import registerApi from 'api/register-api';
import axios from 'axios';
import { parse } from 'date-fns';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { AddressViaCep } from 'model/address';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { Partner } from 'model/dashboard';
import { EditStep } from 'model/edit-paths';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import { References } from 'model/physical-person';
import { useSelectLists } from 'provider/select-list';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientService from 'services/client-service';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import { unMaskedCurrency, unMaskedFieldsValues } from 'shared/util/register-utils';
import {
  employmentBondOptions,
  genderType,
  identityDocumentTypes,
  mailingDestinationType,
  nationalityType,
  ownCar,
  ownMotorbike,
  scholarity,
} from 'shared/util/select-utils';
import { UF } from 'shared/util/states';
import StringUtils from 'shared/util/string-utils';

interface RegisterData {
  steps: OrganizationsSystemSteps[];
  editSteps: EditStep[];
  currentPartner: number | null;
  actualStep: number;
  isOpenEndModal: boolean;
  initialClientData: Client | null;
  initialPartnerData: Partner | null;
  registerStatus: string;
  onClickNext: (newData: Client, isGuarantorRegister?: boolean) => void;
  onClickBack: () => void;
  isFirstForm: () => boolean;
  isLastForm: () => boolean;
  getClientData: () => void;
  getCepData: (cep: string) => void;
  validateMajority: (date: string) => boolean;
  handleSetEndModal: () => void;
  resetStates: () => void;
  setInitialClientData: (Register: Client) => void;
  setInitialPartnerData: (register: Partner | null) => void;
  setFiles: (file: Attachment[]) => void;
  files: Attachment[];
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  isLoadingInitialData: boolean;
  setActualStep: (step: number) => void;
  setIsAdmin: (value: boolean) => void;
  references: References[];
  setReferences: (value: References[]) => void;
  setEditSteps: (editSteps: EditStep[]) => void;
  setCurrentPartner: (index: number) => void;
  currentEditStep: EditStep | null;
  setCurrentEditStep: (editStep: EditStep) => void;
  setClientFiles: () => void;
  addingPartner: boolean;
  setAddingPartner: (set: boolean) => void;
  cepDetails?: AddressViaCep;
}

interface RegisterProps {
  children: ReactNode;
}

const EditFormContext = createContext<RegisterData>({} as RegisterData);

export const EditFormProvider = ({ children }: RegisterProps) => {
  const history = useHistory();
  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [editSteps, setEditSteps] = useState<EditStep[]>([]);
  const [currentEditStep, setCurrentEditStep] = useState<EditStep | null>(null);
  const [files, setFiles] = useState<Attachment[]>([]);
  const [actualStep, setActualStep] = useState(0);
  const [initialClientData, setInitialClientData] = useState<Client | null>(null);
  const [initialPartnerData, setInitialPartnerData] = useState<Partner | null>(null);
  const [currentPartner, setCurrentPartner] = useState<number | null>(null);
  const [isOpenEndModal, setIsOpenEndModal] = useState(false);
  const [addingPartner, setAddingPartner] = useState(false);
  const [registerStatus, setRegisterStatus] = useState('');
  const [stateId, setStateId] = useState<number>(0);
  const [cityId, setCityId] = useState<number>(0);
  const [cepDetails, setCepDetails] = useState<AddressViaCep>();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [references, setReferences] = useState<References[]>([]);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { occupations, mainActivities } = useSelectLists();
  const { id } = useParams<any>();
  const { getResidenceType, residenceType, maritalStatus, getMaritalStatus } = useSelectLists();
  const { isPhysicalPerson } = useSystemParameters();

  useEffect(() => {
    getResidenceType();
    getMaritalStatus();
  }, []);

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  useEffect(() => {
    organizationSystemStepsService.getSteps(StepType.REGISTRATION, StepMoment.BEFORE_VALIDATION).then(res => {
      const newSteps = res.filter(
        step =>
          step.systemStep?.step !== SystemStepCategory.PASSWORD &&
          step.systemStep?.step !== SystemStepCategory.REFERENCES_MONJUA &&
          step.systemStep?.step !== SystemStepCategory.BANK_ACCOUNT_DATA &&
          step.systemStep?.step !== SystemStepCategory.BANK_ACCOUNT_DATA_MULTTIPLO
      );
      setSteps(newSteps);
    });
  }, []);

  useEffect(() => {
    if (registerStatus !== '') {
      setIsOpenEndModal(true);
    }
  }, [registerStatus]);

  useEffect(() => {
    if (cepDetails) {
      if (addingPartner) {
        handleSetNewPartnerAddress(cepDetails);
      } else {
        handleSetNewAddress(cepDetails);
      }
      getCityId(cepDetails.localidade);
    }
  }, [stateId, cepDetails, cityId]);

  useEffect(() => {
    if (currentPartner !== null && initialPartnerData !== null) {
      setFiles(initialPartnerData?.attachments ?? []);
    }
  }, [currentPartner, initialPartnerData]);

  const query = useQuery();

  const onClickNext = res => {
    setIsLoading(true);
    const personHandler = isPhysicalPerson ? handleRegisterPhysicalPerson : handleRegisterLegalPerson;
    const newData = personHandler(res);
    setInitialClientData(newData);
    const registerData: Client = unMaskedFieldsValues(newData as Client);

    if (id) {
      registerData.id = id;
    }
    if (registerData.physicalPerson) {
      registerData.physicalPerson.income = Number(registerData.physicalPerson.income.toFixed(2));
    }
    if (
      registerData.physicalPerson?.identityDocumentUfIssuingBody &&
      registerData.physicalPerson?.identityDocumentUfIssuingBody?.length > 2
    ) {
      registerData.physicalPerson.identityDocumentUfIssuingBody = registerData.physicalPerson.identityDocumentUfIssuingBody.substring(0, 2);
    }
    if (registerData.legalPerson) {
      registerData.legalPerson.revenue = Number(registerData.legalPerson.revenue.toFixed(2));
      registerData.legalPerson.netWorth = Number(registerData.legalPerson.netWorth.toFixed(2));
    }

    const editRegisterData: Client = {
      ...registerData,
      address: { ...registerData.address, uf: registerData.address?.city?.state?.acronym },
    };

    register(registerData);
  };

  const handleRegisterPhysicalPerson = res => {
    const clientBirth = res.birth ? StringUtils.dateFormatMaskToApi(res.birth) : undefined;
    const spouseBirth = res.spouseBirth ? StringUtils.dateFormatMaskToApi(res.spouseBirth) : undefined;
    const rgDateOfIssuance = res.rgDateOfIssuance ? StringUtils.dateFormatMaskToApi(res.rgDateOfIssuance) : undefined;
    const newIncome = unMaskedCurrency(res.income) / 100;
    const occupation = occupations.find(occ => occ.name === res.occupation);
    const choosedEmploymentBond = employmentBondOptions.find(occ => occ.label === res.employmentBond)?.value;
    const scholarityOption = scholarity.find(occ => occ.label === res.scholarity)?.value;
    const ownCarOption = ownCar.find(occ => occ.label === res.ownCar)?.value;
    const ownMotorbikeOption = ownMotorbike.find(occ => occ.label === res.ownMotorbike)?.value;

    let newData = {
      phone: res.phone,
      homePhone: res.homePhone,
      email: res.email,
      employmentBond: choosedEmploymentBond,
      physicalPerson: {
        ...initialClientData?.physicalPerson,
        name: res.name,
        cpf: initialClientData?.physicalPerson?.cpf ?? res.cpf,
        rg: res.rg,
        birth: clientBirth,
        ofAge: true,
        motherName: res.motherName,
        fatherName: res.fatherName,
        genderType: genderType.find(gender => gender.label === res.genderType)?.value,
        rgIssuingBody: res.rgIssuingBody,
        rgUfIssuingBody: res.rgUfIssuingBody,
        rgDateOfIssuance,
        nationalityType: nationalityType.find(nationality => nationality.label === res.nationalityType)?.value,
        admissionDate: res.admissionDate ? StringUtils.dateFormatMaskToApi(res.admissionDate) : '',
        income: newIncome,
        company: res.company,
        companyTimeInYears: Number(res.companyTimeInYears),
        dependents: Number(res.dependents),
        scholarity: scholarityOption,
        ownCar: ownCarOption,
        ownMotorbike: ownMotorbikeOption,
        occupation,
      },
      address: {
        ...initialClientData?.address,
        zipcode: res.zipcode,
        street: res.street,
        number: res.number,
        complement: res.complement,
        district: res.district,
        reference: res.reference,
        mailingDestinationType: mailingDestinationType.find(type => type.label === res.mailingDestinationType)?.value,
        yearsOfResidence: Number(res.yearsOfResidence),
      },
      attachments: files,
    } as Client;

    if (newData.physicalPerson != null) {
      if (res.spouseName !== null && res.spouseName !== '') {
        newData.physicalPerson.spouse = {
          name: res.spouseName,
          birth: spouseBirth ?? '',
          phone: res.spousePhone ?? '',
        };
      } else {
        newData.physicalPerson.spouse = undefined;
      }

      if (res.maritalStatus != null) {
        newData.physicalPerson.maritalStatus = {
          id: maritalStatus.find(status => status.displayValue.toLowerCase() === res.maritalStatus.toLowerCase())?.id,
        };
      }

      if (res.identityDocumentType != null) {
        newData.physicalPerson.identityDocumentType = identityDocumentTypes.find(type => type.label === res.identityDocumentType)?.value;
        newData.physicalPerson.identityDocument = StringUtils.removeNonNumbersFromMaskedValue(res.identityDocument);
        newData.physicalPerson.identityDocumentIssuingBody = res.identityDocumentIssuingBody;
        newData.physicalPerson.identityDocumentUfIssuingBody = res.identityDocumentUfIssuingBody;
        newData.physicalPerson.identityDocumentIssueDate = StringUtils.dateFormatMaskToApi(res.identityDocumentIssueDate);
      }
    }

    if (res.residenceType) {
      newData.address!.residenceType = {
        id: residenceType.find(type => type.displayValue === StringUtils.reverseDisplayValue(res.residenceType))?.id,
      };
    }

    setInitialClientData(newData as Client);
    return newData;
  };

  const handleRegisterLegalPerson = res => {
    const baseDate = StringUtils.dateFormatMaskToApi(res.baseDate);
    const formationDate = StringUtils.dateFormatMaskToApi(res.formationDate);
    const mainActivity = !res.mainActivity?.name ? mainActivities.find(actv => actv.name === res.mainActivity) : res.mainActivity;
    const netWorth = unMaskedCurrency(res.netWorth) / 100;
    const revenue = unMaskedCurrency(res.revenue) / 100;
    const partners = res.partners ? res.partners : initialClientData?.legalPerson?.partners;

    const newData = {
      phone: initialClientData?.phone ?? res.phone,
      email: res.email,
      legalPerson: {
        ...initialClientData?.legalPerson,
        cnpj: res.cnpj,
        corporateName: res.corporateName,
        responsibleName: res.responsibleName,
        formationDate,
        netWorth,
        revenue,
        baseDate,
        mainActivity,
        partners,
        attachments: files,
      },
      address: {
        ...initialClientData?.address,
        zipcode: res.zipcode,
        street: res.street,
        number: res.number,
        complement: res.complement,
        district: res.district,
        reference: res.reference,
      },
      attachments: files,
    } as Client;

    return newData;
  };

  const onClickBack = () => {
    if (actualStep > 0) {
      setActualStep(actualStep - 1);
    }
  };

  const isFirstForm = () => actualStep === 0;

  const isLastForm = () => actualStep === steps.length - 1;

  const register = async (registerData: Client) => {
    const isLegalPerson = !!registerData.legalPerson;

    if (id) {
      try {
        const updateMethod = isLegalPerson ? registerApi.adminUpdateLegalPerson : registerApi.adminUpdatePhysicalPerson;
        const res = await updateMethod(id, registerData);
        setRegisterStatus(res.status.toString());
        !isLegalPerson && history.goBack();
        setIsLoading(false);
      } catch (error: any) {
        setRegisterStatus(error.response.status.toString());
        setIsLoading(false);
      }
    } else {
      try {
        const updateMethod = isLegalPerson ? registerApi.updateLegalPerson : registerApi.updatePhysicalPerson;
        const res = await updateMethod(registerData);
        setRegisterStatus(res.status.toString());
        !isLegalPerson && history.goBack();
        setIsLoading(false);
      } catch (error: any) {
        setRegisterStatus(error.response.status.toString());
        setIsLoading(false);
      }
    }
  };

  const getClientData = async () => {
    setIsLoadingInitialData(true);
    if (id) {
      await clientService.getAllClientData(Number(id)).then(registration => {
        setInitialClientData(registration as Client);
        setFiles(registration.attachments ?? []);
      });
    } else if (account?.client?.id) {
      await clientService.getAllClientData(account?.client?.id).then(registration => {
        setInitialClientData(registration as Client);
        setFiles(registration.attachments ?? []);
      });
    } else {
      const registrationKey = query.get(LocalStorageKeys.REGISTRATION_KEY);

      if (registrationKey != null) {
        await clientService
          .getClientRegistration(registrationKey)
          .then(registration => {
            setInitialClientData(registration as Client);
          })
          .catch(() => {
            history.push('/');
          });
      }
    }
    setIsLoadingInitialData(false);
  };

  const setClientFiles = () => {
    setFiles(initialClientData?.attachments ?? []);
  };

  const getStateId = async (name?: string) => {
    const res = await registerApi.getStates(7, 0, name, '');
    setStateId(res.data.content.find(state => state.name === name)?.id ?? 0);
  };

  const getCityId = async (name?: string) => {
    if (stateId !== 0) {
      const res = await registerApi.getCities(stateId, 1, 0, name);
      setCityId(res.data.content?.[0]?.id ?? 0);
    }
  };

  const handleSetNewAddress = (res: AddressViaCep) => {
    const newAddress = {
      ...initialClientData?.address,
      zipcode: res.cep,
      street: res.logradouro !== '' ? res.logradouro : initialClientData?.address?.street,
      district: res.bairro !== '' ? res.bairro : initialClientData?.address?.district,
      city: {
        ...initialClientData?.address?.city,
        id: cityId,
        name: res.localidade,
        state: {
          ...initialClientData?.address?.city?.state,
          id: stateId,
          name: UF.find(state => state.acronym === res.uf)?.name,
        },
      },
    };

    setInitialClientData({
      ...initialClientData,
      address: newAddress,
    });
  };

  const handleSetNewPartnerAddress = (res: AddressViaCep) => {
    const newAddress = {
      ...initialPartnerData?.address,
      zipcode: res.cep,
      street: res.logradouro !== '' ? res.logradouro : initialPartnerData?.address?.street,
      district: res.bairro !== '' ? res.bairro : initialPartnerData?.address?.district,
      city: {
        ...initialPartnerData?.address?.city,
        id: cityId,
        name: res.localidade,
        state: {
          ...initialPartnerData?.address?.city?.state,
          id: stateId,
          name: UF.find(state => state.acronym === res.uf)?.name,
        },
      },
    };

    const data = initialPartnerData ?? ({} as Partner);
    setInitialPartnerData({
      ...data,
      address: newAddress,
    });
  };

  const getCepData = (cep: string) => {
    cep = cep.replace(/\D/g, '');

    axios.get<AddressViaCep>(`https://viacep.com.br/ws/${cep}/json/`).then(res => {
      if (res.data.cep) {
        setCepDetails(res.data);
        getStateId(UF.find(state => state.acronym === res.data.uf)?.name);
      }
    });
  };

  const validateMajority = (date: string): boolean => {
    const today = new Date();
    const dateConverted = parse(date, 'dd/MM/yyyy', new Date());
    const majorityYear = today.getFullYear() - 18;

    if (dateConverted.getFullYear() <= majorityYear) {
      return true;
    }

    return false;
  };

  const handleSetEndModal = () => {
    setIsOpenEndModal(!isOpenEndModal);
  };

  const resetStates = () => {
    setActualStep(0);
    setInitialClientData(null);
    setRegisterStatus('');
  };

  return (
    <EditFormContext.Provider
      value={{
        steps,
        editSteps,
        setEditSteps,
        actualStep,
        getCepData,
        onClickNext,
        onClickBack,
        isFirstForm,
        isLastForm,
        getClientData,
        validateMajority,
        initialClientData,
        initialPartnerData,
        isOpenEndModal,
        handleSetEndModal,
        resetStates,
        registerStatus,
        files,
        setFiles,
        setInitialClientData,
        setInitialPartnerData,
        currentPartner,
        setCurrentPartner,
        setIsLoading,
        isLoading,
        isLoadingInitialData,
        setActualStep,
        setIsAdmin,
        references,
        setReferences,
        currentEditStep,
        setCurrentEditStep,
        setClientFiles,
        addingPartner,
        setAddingPartner,
        cepDetails,
      }}
    >
      {children}
    </EditFormContext.Provider>
  );
};

export const useEditForm = () => useContext(EditFormContext);
