import { StyledInnerContainer } from './styles';
import OperationCancelledIllustration from '../../../images/operation-cancelled.png';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import ContractContext from 'context/contract';

import { RoutePath } from 'model/enums/route-path';
import useResponsiveMobile from 'shared/util/use-responsive-hook';

const OperationCancelled = ({ t }: WithTranslation) => {
  const history = useHistory();

  const { signatureKey } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }
  }, []);

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.operationCancelled.title')}</h1>
        <span>{t('contract.waitingStep.operationCancelled.subtitle')}</span>
        <img src={OperationCancelledIllustration} alt="operation-cancelled-illustration" />

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(OperationCancelled);
