export default {
  primaryColor: '#9733FD',
  primaryColorDarker: '#164877',
  primaryColorLight: '#FDF200',
  customColorText: '#fff',
  titleText: '#07203C', //
  paragraphText: '#656666', //
  lpColors: {
    headerLp: '#102C44',
    headerTextColor: '#9733FD',
    openingScreen: '#FFFFFF',
    infinityDataScreen: '#9733FD',
    infinityDataText: '#FFFFFF', //
    infinityDataCircle: '#58C7DC', //
    infinityDataIcon: '#58C7DC', //
    whatIsScreen: '#FDF200',
    whatIsScreenTextColor: '#4C4D4D',
    whatIsIcon: '#1F6E95', //
    stepColor: '#1F6E95', //
    stepByStepScreen: '#FFFFFF',
    faqScreen: '#F9F3FF',
    footerLp: '#332E2D',
    simulatorButton: '#9733FD', //
    color1: '#FDF200', //yellow
    color2: '#E0D500', //yellow2
    color3: '#9733FD', //purple1
    color4: '#9733FD', //blue2
    color5: '#FDF200', //yellow
    color6: '#F3F8FF', //light gray
  },
};
