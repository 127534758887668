import React from 'react';
import { useTheme } from 'styled-components';

export const Logo = ({ isWhite }) => {
  const theme = useTheme();

  return (
    <svg width="113" height="26" viewBox="0 0 113 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M105.127 19.7174L107.919 25.1H112.278L109.512 19.7397L102.327 5.82316L92.3739 25.1H96.7329L99.5246 19.7174L102.353 19.7007L105.127 19.7174ZM0.722656 25.0163H4.6271V15.3807H10.1826L0.722656 5.74228V25.0163ZM15.9835 15.3807V25.0163H19.6397V5.74228L10.1826 15.3807H15.9835ZM93.4532 5.68092H89.5125V15.3584C89.5125 16.9062 88.9994 18.2142 87.9758 19.2851C86.9523 20.3561 85.7391 20.8915 84.3363 20.8915C82.769 20.8915 81.4805 20.2389 80.4709 18.9281C79.6092 17.8321 79.1797 16.6413 79.1797 15.3584V5.68092H74.974V15.3193C74.974 18.0552 75.8776 20.3561 77.682 22.2218C79.5032 24.0848 81.6813 25.0163 84.222 25.0163C86.838 25.0163 89.0328 24.0904 90.801 22.2413C92.5691 20.3895 93.4532 18.0971 93.4532 15.3584V5.68092ZM68.8887 15.3584C68.8887 16.7082 68.4648 17.9297 67.6197 19.0285C66.6576 20.2306 65.3245 20.833 63.6205 20.833V25.0163C65.5783 25.0163 67.6058 24.0848 69.7058 22.2218C71.8031 20.3561 72.8517 18.0692 72.8517 15.3584V5.80085H68.8887V15.3584ZM42.3385 25.0163H46.2457V15.3807H51.7984L42.3385 5.74228V25.0163ZM57.655 15.3807H52.1024L61.5595 25.0163V5.74228H57.655V15.3807ZM36.2475 15.3584C36.2475 14.0113 35.8152 12.787 34.9563 11.691C33.9578 10.4471 32.6638 9.82521 31.0713 9.82521C29.6685 9.82521 28.4554 10.369 27.4318 11.4511C26.4195 12.536 25.9147 13.8384 25.9147 15.3584C25.9147 16.6413 26.3442 17.8321 27.2032 18.9281C28.2155 20.2389 29.504 20.8915 31.0713 20.8915C32.4742 20.8915 33.6873 20.3561 34.7108 19.2851C35.7344 18.2142 36.2475 16.9062 36.2475 15.3584ZM37.4774 8.51722C39.2846 10.3802 40.1882 12.6615 40.1882 15.3584C40.1882 18.0971 39.3041 20.3895 37.536 22.2413C35.765 24.0904 33.573 25.0163 30.957 25.0163C28.8709 25.0163 26.8127 24.0848 24.7768 22.2218C22.7437 20.3449 21.7258 18.0552 21.7258 15.3584C21.7258 12.6364 22.6099 10.3495 24.378 8.4977C26.1601 6.63472 28.3522 5.70045 30.957 5.70045C33.4977 5.70045 35.6702 6.6403 37.4774 8.51722ZM103.067 4.89167L102.623 5.25143L105.167 0.320679L108.363 0.323468L103.067 4.89167Z"
        fill={isWhite ? '#fff' : '#000'}
      />
    </svg>
  );
};
