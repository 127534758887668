import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RoutePath } from 'model/enums/route-path';
import { useAdmin } from 'provider/admin-general';
import { BalanceRequestStatus, BalanceTransactionStatus } from 'model/enums/account-status';
import { TableId, TableValues } from '../components/tables-list';
import myAccountService from 'services/my-account-service';
import StringUtils from 'shared/util/string-utils';
import moment from 'moment';
import { Status } from 'features/admin/dashboard/components/status';
import { PageableResponse } from 'model/pageable';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { Myaccounthistories } from 'model/enums/my-account';
import { SubsidiariesService } from 'services/subsidiaries-service';
import FinancingStatusType from 'model/enums/financing-status-type';
import CheckBoxComponent from '../components/checkInput';

const filterBalanceTransaction = [BalanceTransactionStatus.BLOCKED, BalanceTransactionStatus.CANCELLED, BalanceTransactionStatus.FINISHED];

const filterBalanceRequest = [
  BalanceRequestStatus.PENDING_PAYMENT,
  BalanceRequestStatus.PROCESSING,
  BalanceRequestStatus.CANCELLED,
  BalanceRequestStatus.FINISHED,
  BalanceRequestStatus.WAITING_TO_APPLY_AMOUNT,
];

export const useExtractsScreen = () => {
  const { t } = useTranslation();
  const [subsidiaryName, setSubsidiaryName] = useState<string>();
  const [searchName, setSearchName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterStatusValues, setfilterStatusValues] = useState<string[]>([]);
  const [balancesRequestTransactions, setBalancesRequestTransactions] = useState<TableValues[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [listResponse, setListResponse] = useState<PageableResponse<any>>();
  const [selectedStep, setSelectedStep] = useState<Myaccounthistories>();
  const [totalValue, setTotalValue] = useState<number>();
  const location = useLocation();
  const history = useHistory();
  const [idsCession, setIdsCession] = useState<number[]>([]);
  const [checkedInputs, setCheckedInputs] = useState<boolean[]>([]);

  const addOrRemoveId = (id: number, index: number) => {
    setIdsCession(prevIds => {
      if (prevIds.includes(id)) {
        return prevIds.filter(existingId => existingId !== id);
      } else {
        return [...prevIds, id];
      }
    });
    setCheckedInputs(previusArray => {
      let newCheckedInputs = [...previusArray];
      newCheckedInputs[index] = !previusArray[index];
      return newCheckedInputs;
    });
  };

  const deleteCheckeds = () => {
    setIdsCession([]);
    setCheckedInputs(previusArray => previusArray.map(item => false));
    getBalancesList(searchName, page, true);
  };

  const getBranchInfos = async (Id: number) => {
    await SubsidiariesService()
      .getBranchInfos(Id)
      .then(res => {
        setSubsidiaryName(res?.name);
      });
  };

  const { id } = useParams<{ id: string }>();

  const { statusFilters, createdDates, resetCreatedDates, resetStatusFilters } = useAdmin();

  useEffect(() => {
    getBalancesList(searchName, 0);
  }, [searchName, statusFilters, createdDates]);

  useEffect(() => {
    getBalancesList(searchName, page);
  }, [page]);

  useEffect(() => {
    cleanAppliedFilters();
  }, [selectedStep]);

  useEffect(() => {
    if (id) {
      getBranchInfos(Number(id));
    }
    if (location.pathname.includes(RoutePath.HISTORY_OF_BALANCE_REQUEST)) {
      setSelectedStep(Myaccounthistories.HISTORYOFBALANCEREQUEST);
    } else {
      setSelectedStep(Myaccounthistories.HISTORYOFTRANSACTIONS);
    }
  }, []);

  const getBalancesList = async (searchName: string, page: number, cleaning = false) => {
    setIsLoadingTable(true);

    if (selectedStep === Myaccounthistories.HISTORYOFBALANCEREQUEST) {
      setfilterStatusValues(filterBalanceRequest);
      await myAccountService
        .getBalancesRequestTransactions(id, {
          searchName: searchName,
          size: 8,
          page: page,
          paymentStatus: statusFilters,
          createdDates: createdDates,
        })
        .then(res => {
          setBalancesRequestTransactions([
            {
              header: t('admin.myAccount.balanceSolicitation'),
              values: res.page.content.map(line => String(line.depositNumber ?? '').padStart(5, '0')),
              ids: res.page.content.map(line => ({ first: line.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push('/admin/novo-saldo', {
                    state: { paymentId: ids.first },
                  });
                }
              },
            },
            {
              header: t('admin.myAccount.solicitationDate'),
              values: res.page.content.map(line => formatDateToLocalFormat(line.createdDate)),
              ids: res.page.content.map(line => ({ first: line.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push('/admin/novo-saldo', {
                    state: { paymentId: ids.first },
                  });
                }
              },
            },
            {
              header: t('admin.myAccount.valueAdd'),
              values: res.page.content.map(line => StringUtils.moneyMaskPtbr(line.amount)),
              ids: res.page.content.map(line => ({ first: line.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push('/admin/novo-saldo', {
                    state: { paymentId: ids.first },
                  });
                }
              },
            },
            {
              header: t('admin.dashboard.tableHeaders.status'),
              values: res.page.content.map(mt => <Status text={t(`enum.financingStatus.${mt.status}`)} color={mt.status ?? ''} />),
              ids: res.page.content.map(line => ({ first: line.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push('/admin/novo-saldo', {
                    state: { paymentId: ids.first },
                  });
                }
              },
            },
          ]);

          setTotalValue(res.total);
          setListResponse(res.page);
          setPage(res.page.pageable.pageNumber);
          setTotalPages(res.page.totalPages);
        });
    } else if (selectedStep === Myaccounthistories.HISTORYOFTRANSACTIONS) {
      setfilterStatusValues(filterBalanceTransaction);
      await myAccountService
        .getBalancesTransactions(id, {
          searchName: searchName,
          size: 8,
          page: page,
          paymentStatus: statusFilters,
          createdDates: createdDates,
        })
        .then(res => {
          setBalancesRequestTransactions([
            {
              header: t('admin.myAccount.cession'),
              values: res.page.content.map((line, index) => {
                if (!line.isCreditAssignmentDone && line.status === FinancingStatusType.FINISHED) {
                  return (
                    <CheckBoxComponent
                      cleaning={cleaning}
                      defaultState={checkedInputs[index]}
                      onChange={() => addOrRemoveId(line.id, index)}
                    />
                  );
                } else {
                  return '';
                }
              }),
              ids: res.page.content.map(line => ({ first: line.financing.id })),
            },
            {
              header: t('admin.myAccount.ccb'),
              values: res.page.content.map(line => String(line.financing.ccb ?? '').padStart(5, '0')),
              ids: res.page.content.map(line => ({ first: line.financing.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push(`/admin/procedimentos/analise/${ids.first}`);
                }
              },
            },
            {
              header: t('admin.myAccount.value'),
              values: res.page.content.map(line => StringUtils.moneyMaskPtbr(line.amount)),
              ids: res.page.content.map(line => ({ first: line.financing.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push(`/admin/procedimentos/analise/${ids.first}`);
                }
              },
            },
            {
              header: t('admin.myAccount.solicitationDate'),
              values: res.page.content.map(line => moment(line.createdDate).format('DD/MM/YY')),
              ids: res.page.content.map(line => ({ first: line.financing.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push(`/admin/procedimentos/analise/${ids.first}`);
                }
              },
            },
            {
              header: t('admin.dashboard.tableHeaders.status'),
              values: res.page.content.map(mt => <Status text={t(`enum.financingStatus.${mt.status}`)} color={mt.status ?? ''} />),
              ids: res.page.content.map(line => ({ first: line.financing.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push(`/admin/procedimentos/analise/${ids.first}`);
                }
              },
            },
            {
              header: t('admin.dashboard.tableHeaders.cessionStatus'),
              values: res.page.content.map(mt => {
                const cessionStatus = mt.isCreditAssignmentDone ? 'admin.myAccount.cessionGenerated' : 'admin.myAccount.cessionPending';
                if (mt.status === FinancingStatusType.FINISHED) {
                  return (
                    <Status
                      text={t(cessionStatus)}
                      color={mt.isCreditAssignmentDone ? FinancingStatusType.FINISHED : FinancingStatusType.PENDING}
                    />
                  );
                } else {
                  return <Status text={t('admin.myAccount.notAvailable')} color={FinancingStatusType.PENDING} />;
                }
              }),
              ids: res.page.content.map(line => ({ first: line.financing.id })),
              onClick: (ids?: TableId) => {
                if (ids) {
                  history.push(`/admin/procedimentos/analise/${ids.first}`);
                }
              },
            },
          ]);
          setTotalValue(res.total);
          setListResponse(res.page);
          setPage(res.page.pageable.pageNumber);
          setTotalPages(res.page.totalPages);
        });
    }
    setIsLoadingTable(false);
  };

  const cleanAppliedFilters = () => {
    resetCreatedDates();
    resetStatusFilters();
  };

  const downloadPDF = () => {
    myAccountService.downloadPDF({ ids: idsCession }).then(res => window.location.reload());
  };

  return {
    page,
    setPage,
    totalPages,
    isLoadingTable,
    listResponse,
    setIsLoadingTable,
    setSearchName,
    searchName,
    filterStatusValues,
    cleanAppliedFilters,
    balancesRequestTransactions,
    selectedStep,
    setSelectedStep,
    subsidiaryName,
    totalValue,
    idsCession,
    setIdsCession,
    deleteCheckeds,
    downloadPDF,
    setCheckedInputs,
  };
};
