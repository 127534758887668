import { StyledInnerContainer, CentralizedContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import checkGif from 'images/gif/check.gif';

const StepCompleted = ({ t }: WithTranslation) => {
  return (
    <>
      <MobileHeader />
      <StyledInnerContainer>
        <CentralizedContainer>
          <img src={checkGif} alt="check gif" height="150px" style={{ margin: 0 }} />
          <h1>{t('contract.waitingStep.stepCompleted.title')}</h1>
          <span>{t('contract.waitingStep.stepCompleted.subtitle')}</span>
        </CentralizedContainer>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(StepCompleted);
