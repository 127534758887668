import MobileHeader from 'components/mobile-header';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import { FormContainer, FormStyled, PageTitleSection, StyledContainer } from '../styles';
import { StyledInnerContainer, StyledCardShowContractContainer } from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import ContractContext from 'context/contract';
import contractService from 'services/contract-service';
import { RoutePath } from 'model/enums/route-path';
import CustomButton from 'components/custom-button';
import { format, isDate, parse } from 'date-fns';
import { Mask } from 'model/mask-types';
import InputText from 'components/styled-input';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import StringUtils, { validateCpf } from 'shared/util/string-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';

const ConfirmDetails = ({ t }: WithTranslation) => {
  const history = useHistory();

  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const { documentPresignUrl, signatureKey, personSignatureId, contractResponse } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  const formSchema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!)),
    birth: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required')),
  });

  const onSubFunction = props => {
    const transformBirth = format(props.birth, 'yyyy-MM-dd');

    if (personSignatureId != null) {
      const SignatoryData = {
        personSignatureId,
        name: props.name,
        cpf: StringUtils.removeNonNumbersFromMaskedValue(props.cpf),
        birth: transformBirth,
      };

      contractService.sendPersonDataConfirmation(SignatoryData).then(() => {
        return history.push(RoutePath.PROOF_LIFE);
      });
    }
  };

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }

    if (!signatureKey) {
      return history.push(RoutePath.HOME);
    }
  }, [signatureKey, isMobile]);

  return (
    <StyledContainer>
      <MobileHeader />
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(`/lets-sign`)} />
            <span>{t('letsSign.stepOfTotal', { step: '1' })}</span>
          </ArrowContainer>
          <p>{t('contract.confirmDetails.title')}</p>
        </PageTitleSection>
        <ReactHookFormProvider schema={formSchema} onSubmit={onSubFunction}>
          <FormStyled>
            <FormContainer>
              <label>{t('letsSign.completeName')}</label>
              <InputText
                readOnly
                isRequired
                name="name"
                placeholder={t('letsSign.completeName')}
                defaultValue={contractResponse?.client?.physicalPerson?.name}
              />
              <label>{t('letsSign.cpf')}</label>
              <InputText
                readOnly
                isRequired
                name="cpf"
                placeholder={t('letsSign.cpf')}
                mask={Mask.CPF}
                maxLength={11}
                defaultValue={contractResponse?.client?.physicalPerson?.cpf}
              />
              <label>{t('letsSign.birthDay')}</label>
              <InputText
                readOnly
                isRequired
                name="birth"
                placeholder={t('letsSign.birthDay')}
                maxLength={10}
                mask={Mask.BIRTHDAY}
                defaultValue={StringUtils.dateFormatMask(contractResponse?.client?.physicalPerson?.birth ?? '')}
              />
            </FormContainer>
            <CustomButton className="mobile-button--gofoward" type="submit">
              {t('letsSign.comfirm')}
            </CustomButton>
          </FormStyled>
        </ReactHookFormProvider>
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(ConfirmDetails);
