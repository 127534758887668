import CardForDashboard from 'components/card-for-dashboard';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import SimulatorDashboard from 'components/simulator-dashboard';
import { EyeButton } from 'components/styled-input/styles';
import { ORGANIZATION_NAME } from 'config/constants';
import BankAccountForm from 'features/simulator-result/dashboard/bank-account-form';
import BankDetailsRegistrationFormPix from 'features/simulator-result/dashboard/bank-details-registration-form-pix';
import useFormatPaymentTypeShow from 'hooks/use-format-payment-type-to-show';
import { ReactComponent as CardSvg } from 'images/blue-card.svg';
import { ReactComponent as FraudAnalysisSvg } from 'images/blue-fraud-analysis.svg';
import { ReactComponent as GuarantorsSvg } from 'images/blue-guarantors.svg';
import Eye from 'images/eye.svg';
import { AuthBiometric } from 'model/auth-biometric';
import { BankAccount } from 'model/bank-account';
import { Client } from 'model/client';
import { ClieloStatus, ContestationStatus, CreditCard } from 'model/credit-card';
import { Authority } from 'model/enums/authority';
import { BankAccountType } from 'model/enums/bank-account-type';
import { BiometryStatus } from 'model/enums/biometric-status';
import CodeStatusCielio from 'model/enums/code-status-cielo';
import { ContractStatus } from 'model/enums/contract-status';
import { ICardData } from 'model/enums/dashboard';
import DashboardCardCategory from 'model/enums/dashboard-card-category';
import { FinancingStatus } from 'model/enums/financing-status';
import FinancingStatusType from 'model/enums/financing-status-type';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { HcredFinancingStatus } from 'model/enums/hcred-financing-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { OrganizationName } from 'model/enums/organization-name';
import { RegistrationStatus } from 'model/enums/registration-status';
import StepType from 'model/enums/step-type';
import SystemStepCategory from 'model/enums/system-step-category';
import { TwoFactorStatus } from 'model/enums/two-factors-status';
import TypeVerifyIdentity from 'model/enums/type-verify-identity';
import { BankAccountInFinancing, Contract, Financing } from 'model/financing';
import { Guarantor } from 'model/guarantor';
import { Insurance } from 'model/insurance';
import { useAdmin } from 'provider/admin-general';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiCreditCard, FiGrid, FiInfo, FiKey, FiShield, FiSmile, FiStar } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import clientService from 'services/client-service';
import creditCardService from 'services/credit-card-service';
import financingService from 'services/financing-service';
import guarantorService from 'services/guarantor-service';
import insuranceService from 'services/insurance-service';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import StringUtils from 'shared/util/string-utils';
import { hasAuthorities, stepsOrdererByStepMomentAndOrderStep } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { AnalisysInfoModal } from '../clientData/components/analisysInfoModal';
import { AddAnalistButton } from '../clientData/styles';
import { Status } from '../dashboard/components/status';
import { StepBarFinanceProgress } from '../stepBarFinanceProgress';
import { TabDetails } from './components/custom-organization';
import ModalAnalyzingCredit from './components/modal-analyzing-credit';
import { TabContracts } from './components/tabContracts';
import { TabPayments } from './components/tabPayments';
import {
  ButtonsContainer,
  CardContent,
  CardText,
  CardsContainer,
  GuarantorCount,
  IconBox,
  PageContainer,
  StatusContainer,
  Tab,
  TabBox,
  TabClientName,
  TabContainer,
  TabContent,
  TabHeaderContainer,
  TabHeaderInnerContainer,
  TabTitleHeader,
  Title,
  TitleHeader,
} from './styles';

interface StepCard extends ICardData {
  redirectTo?: string;
  redirectPayload?: unknown;
}

export interface TabSelection {
  tabNumber: number;
  tabStep?: SystemStepCategory;
}

const excludeSystemSteps = [SystemStepCategory.SIMULATOR, SystemStepCategory.FINANCING_ANALYSIS, SystemStepCategory.SCORE_ANALYSIS];

export const CustomerAnalysis = () => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { t } = useTranslation();

  const { formatPaymentTypeToShow } = useFormatPaymentTypeShow();

  const [tabsSystemSteps, setTabsSystemSteps] = useState<SystemStepCategory[]>([SystemStepCategory.CONTRACT]);
  const [tabSelected, setTabSelected] = useState<TabSelection>({ tabNumber: 1 });
  const { financingId } = useParams<{ financingId: string }>();
  const [systemSteps, setSystemSteps] = useState<SystemStepCategory[]>([]);
  const [allGuarantors, setAllGuarantors] = useState<Guarantor[]>([]);
  const [bankAccountInfos, setBankAccountInfos] = useState<BankAccountInFinancing | null>(null);
  const [isShowAnalyzeFinancingModal, setIsShowAnalyzeFinancingModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [financingCodes, setFinancingCodes] = useState<{ id?: string; ccb?: string } | null>(null);
  const [clientId, setClientId] = useState<number>();
  const [authBiometry, setAuthBiometry] = useState<AuthBiometric | null>(null);
  const [creditCard, setCreditCard] = useState<CreditCard | null>(null);
  const [cieloStatus, setCieloStatus] = useState<ClieloStatus>();
  const [loading, setLoading] = useState(false);
  const [financeStatus, setFinanceStatus] = useState<FinancingStatusType | null>(null);
  const [financeHcredStatus, setFinanceHcredStatus] = useState<HcredFinancingStatus | null>(null);
  const { handleSetStepFinance, stepFinance } = useAdmin();
  const theme = useTheme();

  const [isShowApproveCreditButton, setIsShowApproveCreditButton] = useState<boolean>(false);
  const [isShowSimulatorModal, setIsShowSimulatorModal] = useState<boolean>(false);
  const [isIdentityVerificationButtonLoading, setIsIdentityVerificationButtonLoading] = useState<boolean>(false);
  const [isConfirmationButtonIsLoading, setIsConfirmationButtonIsLoading] = useState<boolean>(false);

  const [isShowModalCreateBankAccount, setIsShowModalCreateBankAccount] = useState<boolean>(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [isLoadingBankAccount, setIsLoadingBankAccount] = useState<boolean>(false);
  const [verifyIdentity, setVerifyIdentity] = useState<TypeVerifyIdentity>();
  const [finance, setFinance] = useState<Financing>();
  const [financingInsurance, setFinancingInsurance] = useState<Insurance>();
  const [showAnalisysInfoModal, setShowAnalisysInfoModal] = useState<boolean>(false);

  const account = useSelector((state: IRootState) => state.authentication.account);

  const [clientData, setClientData] = useState<Client>();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const isHcred = ORGANIZATION_NAME === OrganizationName.HCRED;

  const [contestationStatus, setContestationStatus] = useState<ContestationStatus>();

  useEffect(() => {
    if (ORGANIZATION_NAME !== OrganizationName.MONJUA) {
      setTabsSystemSteps([...tabsSystemSteps, SystemStepCategory.PAYMENT]);
    }
  }, []);

  useEffect(() => {
    getOrganizationSteps();
    getFinancingCode();
    getAllContractsFinancing();
  }, []);

  useEffect(() => {
    if (clientId != null) {
      getClientData(clientId);
      getContestationStatus();
    }
  }, [clientId]);

  useEffect(() => {
    if (systemSteps.length > 0) {
      if (systemSteps.includes(SystemStepCategory.GUARANTORS)) {
        getAllGuarators();
      }

      if (systemSteps.includes(SystemStepCategory.BANK_ACCOUNT_DATA)) {
        getBankAccount();
      }

      if (systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) || systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)) {
        getAuthBiometry();
      }
    }
  }, [systemSteps]);

  useEffect(() => {
    setIsShowApproveCreditButton(false);

    if (financeStatus === FinancingStatusType.IN_PROGRESS) {
      if (
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        authBiometry?.twoFactorAuth?.[0]?.verified &&
        authBiometry?.biometrics?.status === BiometryStatus.DONE &&
        authBiometry?.biometrics?.score != null &&
        !stepFinance?.isConfirmed
      ) {
        setIsShowApproveCreditButton(true);
      } else if (
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        authBiometry?.biometrics?.status === BiometryStatus.DONE &&
        authBiometry.biometrics.score != null &&
        !stepFinance?.isConfirmed
      ) {
        setIsShowApproveCreditButton(true);
      } else if (
        verifyIdentity === TypeVerifyIdentity.NECESSARY &&
        systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
        !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
        authBiometry?.twoFactorAuth[0]?.verified &&
        !stepFinance?.isConfirmed
      ) {
        setIsShowApproveCreditButton(true);
      } else if (
        !stepFinance?.isConfirmed &&
        (verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY ||
          (!systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
            !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)))
      ) {
        setIsShowApproveCreditButton(true);
      }
    }
  }, [authBiometry, bankAccountInfos, verifyIdentity]);

  const getBankAccount = async () => {
    if (financingId) {
      const backAccount = await financingService.getFinancingBankAccount(Number(financingId));
      setBankAccountInfos(backAccount.data);
    }
  };

  const getIfClientNeedsToDoBiometricsOrAuthentication = (clientId: number) => {
    clientService.getIfClientNeedsToDoBiometricsOrAuthentication(clientId).then(result => setVerifyIdentity(result.isToVerifyIdentity));
  };

  useEffect(() => {
    if (systemSteps.includes(SystemStepCategory.CREDIT_CARD)) {
      getCreditCardInfos();
    }
  }, [clientId, systemSteps]);

  const getAllGuarators = async () => {
    if (financingId) {
      const guarantors = await guarantorService.getAllGuarantorsInFinancing(Number(financingId));
      setAllGuarantors(guarantors.data.guarantors);
    }
  };

  const getAuthBiometry = async () => {
    if (financingId != null) {
      const res = await financingService.getAuthenticateAndBiometry(Number(financingId));
      setAuthBiometry(res);
      setIsIdentityVerificationButtonLoading(false);
    }
  };

  const getCreditCardInfos = async () => {
    if (clientId) {
      const res = await clientService.getCreditCardFromFinancing(Number(financingId));
      setCieloStatus(res.cielo);
      setCreditCard(res.financingCreditCards?.[0]?.creditCard ?? {});
    }
  };

  const getOrganizationSteps = async () => {
    const steps = await organizationsSystemStepsService.getSteps(StepType.CCB);
    // tslint:disable-next-line: no-assign-mutated-array
    const systemStepsResponse = steps.sort(stepsOrdererByStepMomentAndOrderStep).map(step => step.systemStep.step);
    setSystemSteps(systemStepsResponse);
  };

  const getFinancingCode = async (isCheckCreditApprovalModal?: boolean) => {
    await financingService.getFinancingById(Number(financingId)).then(response => {
      setFinancingCodes({
        id: response?.identification,
        ccb: response.ccb,
      });
      setClientId(response?.client?.id);
      handleSetStepFinance(response);
      setFinance(response);

      if (response?.isInsured) {
        getFinancingInsurance(Number(financingId));
      }

      if (response?.financingStatus) {
        setFinanceStatus(response?.financingStatus);
      }
      if (response?.financingHcredStatus) {
        setFinanceHcredStatus(response?.financingHcredStatus);
      }

      if (isCheckCreditApprovalModal) {
        handleDisplayCreditApprovalModals(response.isConfirmed);
      }

      if (response?.client?.id != null && response.biometricsIsNecessary) {
        getIfClientNeedsToDoBiometricsOrAuthentication(response.client.id);
      }

      if (!response.biometricsIsNecessary) {
        setVerifyIdentity(TypeVerifyIdentity.IS_NOT_NECESSARY);
      }
    });
  };

  const getFinancingInsurance = async (financingId: number) => {
    await insuranceService.getInsuranceByFinancing(financingId).then(insurance => {
      setFinancingInsurance(insurance);
    });
  };

  const biometricsRequest = () => {
    setIsIdentityVerificationButtonLoading(true);
    financingService.biometricsRequest(Number(financingId)).then(() => getAuthBiometry());
    getFinancingCode();
  };

  const twoFactorAuthRequest = () => {
    setIsIdentityVerificationButtonLoading(true);
    financingService.twoFactorAuthRequest(Number(financingId)).then(() => getAuthBiometry());
    getFinancingCode();
  };

  const getContestationStatus = () => {
    if (clientId != null) {
      creditCardService.getContestationStatus(clientId).then(contestation => {
        const currentContestation = contestation.filter(value => value?.financing?.id === Number(financingId))[0];
        setContestationStatus(currentContestation);
      });
    }
  };

  const setStatusText2fa = () => {
    if (verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY) {
      return t('dashboard.authenticationNotRequired');
    } else if (verifyIdentity === TypeVerifyIdentity.NECESSARY) {
      const status = authBiometry?.twoFactorAuth?.[0]?.status;
      const verified = authBiometry?.twoFactorAuth?.[0]?.verified;

      switch (status) {
        case TwoFactorStatus.REPLIED:
          return verified ? t(`enum.authBiometryStatus.${status}.approved`) : t(`enum.authBiometryStatus.${status}.denied`);

        case TwoFactorStatus.SENT:
          return verified ? t(`enum.authBiometryStatus.${TwoFactorStatus.REPLIED}.approved`) : t(`enum.authBiometryStatus.${status}`);

        default:
          return t(`enum.authBiometryStatus.${status}`);
      }
    }
  };

  const getCardsForDashboard = () => {
    const dashboardCards: StepCard[] = [getDashboardCard(DashboardCardCategory.INSURANCE)];
    const systemStepCards: (StepCard | undefined)[] = systemSteps
      .filter(systemStep => !excludeSystemSteps.includes(systemStep) && !tabsSystemSteps.includes(systemStep))
      .map(systemStep => getSystemStepCard(systemStep))
      .filter(value => value !== undefined);

    return [...dashboardCards, ...systemStepCards];
  };

  const getDashboardCard = (category: DashboardCardCategory): StepCard => {
    switch (category) {
      case DashboardCardCategory.INSURANCE:
        return {
          key: category,
          icon: (
            <IconBox>
              <FiShield size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.insurance'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.insurance', { ticketNo: financingInsurance?.ticketNo })}</CardText>,
          isActive: true,
          isDataFilled: true,
          hide: !finance?.isInsured,
          redirectTo: `/admin/seguro/${financingId}`,
          redirectPayload: financingInsurance,
        };
    }
  };

  const getSystemStepCard = (step: SystemStepCategory): StepCard | undefined => {
    switch (step) {
      case SystemStepCategory.GUARANTORS:
        return {
          key: step,
          icon: <GuarantorsSvg />,
          title: t('admin.customerAnalysis.stepCards.guarantors'),
          content: (
            <CardContent>
              {allGuarantors?.length > 0 ? <CardText>{allGuarantors[0].name}</CardText> : <></>}
              {allGuarantors?.length > 1 ? (
                <GuarantorCount>
                  <span>+{allGuarantors?.length}</span>
                </GuarantorCount>
              ) : (
                <></>
              )}
            </CardContent>
          ),
          isActive: true,
          isDataFilled: false,
          redirectTo: '/admin/fiadores/1',
        };

      case SystemStepCategory.BANK_ACCOUNT_DATA:
        return {
          key: step,
          icon: <CardSvg />,
          title: t('admin.customerAnalysis.stepCards.bankAccount'),
          content:
            bankAccountInfos?.bankAccount?.pixType != null ? (
              <CardText>
                {t('admin.customerAnalysis.instantPayment', {
                  pixType: t(`enums.pixType.${bankAccountInfos.bankAccount.pixType}`),
                  pixKey: bankAccountInfos.bankAccount.pixKey,
                })}
              </CardText>
            ) : (
              <CardText>{`${bankAccountInfos?.bankAccount?.type ? BankAccountType[bankAccountInfos.bankAccount.type] : ''} ${
                bankAccountInfos?.bankAccount?.bankAccountNumber ?? '0000'
              }-${bankAccountInfos?.bankAccount?.bankAccountDigit ?? '0'}`}</CardText>
            ),
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/conta-bancaria/${financingId}`,
        };

      case SystemStepCategory.FRAUD_ANALYSIS:
        return {
          key: step,
          icon: <FraudAnalysisSvg />,
          title: t('admin.customerAnalysis.stepCards.fraudAnalysis'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.undefined')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.DOC_FRAUD_ANALYSIS:
        return {
          key: step,
          icon: <FraudAnalysisSvg />,
          title: t('admin.customerAnalysis.stepCards.fraudAnalysis'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.undefined')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiSmile size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.biometry'),
          content: (
            <CardText>
              {verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
                ? t('dashboard.biometricsNotRequired')
                : t(`enum.authBiometryStatus.${authBiometry?.biometrics?.status ?? 'undefined'}`)}
            </CardText>
          ),
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/biometria/${financingId}`,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_RATINGS:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiInfo size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.ratings'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.ratings')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_INSIGHTS:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiGrid size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.insights'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.insights')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_2FA:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiKey size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.2fa'),
          content: <CardText>{setStatusText2fa()}</CardText>,
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/autenticacao/${financingId}`,
        };

      case SystemStepCategory.FRAUD_ANALYSIS_SCORE:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiStar size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.score'),
          content: <CardText>{t('admin.customerAnalysis.textStepCards.score')}</CardText>,
          isActive: true,
          isDataFilled: false,
        };

      case SystemStepCategory.CREDIT_CARD:
        return {
          key: step,
          icon: (
            <IconBox>
              <FiCreditCard size={20} color={color.primaryColor} />
            </IconBox>
          ),
          title: t('admin.customerAnalysis.stepCards.creditCard'),
          content: (
            <CardContent>
              <div className="status">
                <Status text={t(`enum.filterAllStatus.${creditCard?.status}`)} color={creditCard?.status ?? 'undefined'} />
              </div>
              <CardText className="credit">{t('admin.customerAnalysis.textStepCards.creditCard', { number: creditCard?.number })}</CardText>
            </CardContent>
          ),
          isActive: true,
          isDataFilled: false,
          redirectTo: `/admin/cartao/${finance?.id}/${clientId}`,
        };

      default:
        return;
    }
  };

  const getTabContent = () => {
    if (tabSelected.tabNumber === 1) {
      if (
        financeStatus === FinancingStatusType.APPROVED ||
        financeStatus === FinancingStatusType.PENDING_PAYMENT ||
        financeStatus === FinancingStatusType.PENDING_SIGN ||
        financeStatus === FinancingStatusType.FINISHED
      ) {
        return <TabDetails cannotEdit />;
      }
      return <TabDetails />;
    }

    switch (tabSelected.tabStep) {
      case SystemStepCategory.CONTRACT:
        return <TabContracts contracts={contracts} status={financeStatus} />;

      case SystemStepCategory.PAYMENT:
        return <TabPayments />;

      default:
        return <></>;
    }
  };

  const closeModal = () => {
    setShowCancelModal(false);
    setLoading(false);
  };

  const cancelFinancing = async () => {
    if (financingId) {
      setLoading(true);
      const res = await financingService.cancelFinancing(Number(financingId));

      if (res) {
        setLoading(false);
        setShowCancelModal(false);
        history.go(0);
      }
    }
  };

  const getClientData = (clientId: number) => {
    clientService.getAllClientData(clientId).then(setClientData);
  };

  const getAllContractsFinancing = async () => {
    if (financingId) {
      const contractsRes = await financingService.getAllContractsFinancing(Number(financingId));
      setContracts(contractsRes.content);
    }
  };

  const handleDisplayCreditApprovalModals = (isFinancingConfirmed?: boolean) => {
    if (!isFinancingConfirmed) {
      setIsShowSimulatorModal(true);
    } else if (bankAccountInfos?.bankAccount == null && bankAccountInfos?.financingPaymentType === FinancingPaymentType.CLIENT) {
      setIsShowModalCreateBankAccount(true);
    }
  };

  const handleConfirmFinancing = () => {
    if (!isConfirmationButtonIsLoading) {
      setIsConfirmationButtonIsLoading(true);

      financingService.confirmFinancingByAdmin(Number(financingId)).then(() => {
        getFinancingCode(true);
        setIsShowSimulatorModal(false);
        setIsConfirmationButtonIsLoading(false);
        setIsShowApproveCreditButton(false);
      });
    }
  };

  return (
    <>
      {showAnalisysInfoModal && clientData && (
        <AnalisysInfoModal
          status={t(`enum.filterAllStatus.${finance?.financingStatus}`)}
          comment={finance?.consultantComment}
          setShowModal={setShowAnalisysInfoModal}
        />
      )}
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />
        <TitleHeader>
          <Title>
            <div className="firstTitle">
              <button
                onClick={() => {
                  if (location.state?.prevPath) {
                    history.push(location.state.prevPath);
                  } else {
                    history.push('/admin/creditos');
                  }
                }}
              >
                <FiChevronLeft color={color.primaryColor} />
              </button>
              <span className="title">{t('admin.customerAnalysis.pageTitle')}</span>
              <span className="code">{financingCodes?.id ? `#${financingCodes.id}` : ''}</span>
            </div>

            {financingCodes?.ccb && (
              <div>
                <span className="title ccb">{t('admin.customerAnalysis.ccb')}</span>
                <span className="code">{financingCodes?.ccb ? `#${financingCodes.ccb}` : ''}</span>
              </div>
            )}
          </Title>

          <ButtonsContainer>
            {systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA) &&
              financeStatus === FinancingStatusType.IN_PROGRESS &&
              verifyIdentity === TypeVerifyIdentity.NECESSARY &&
              authBiometry?.twoFactorAuth?.[0] == null && (
                <CustomButton onClick={() => twoFactorAuthRequest()} loading={isIdentityVerificationButtonLoading}>
                  {t('admin.customerAnalysis.startAuthentication')}
                </CustomButton>
              )}
            {systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY) &&
              financeStatus === FinancingStatusType.IN_PROGRESS &&
              verifyIdentity === TypeVerifyIdentity.NECESSARY &&
              authBiometry?.biometrics?.status == null &&
              hasAuthorities(account?.authorities, [
                Authority.ROLE_ADMIN,
                Authority.ROLE_INTERNAL_ADMIN,
                Authority.ROLE_MANAGER,
                Authority.ROLE_SELLER,
                Authority.ROLE_FINANCIAL,
                Authority.ROLE_ANALYST,
              ]) &&
              (authBiometry?.twoFactorAuth?.[0]?.verified || !systemSteps.includes(SystemStepCategory.FRAUD_ANALYSIS_2FA)) && (
                <CustomButton onClick={() => biometricsRequest()} loading={isIdentityVerificationButtonLoading}>
                  {t('admin.customerAnalysis.startBiometrics')}
                </CustomButton>
              )}
            {isShowApproveCreditButton &&
              hasAuthorities(account?.authorities, [
                Authority.ROLE_ADMIN,
                Authority.ROLE_INTERNAL_ADMIN,
                Authority.ROLE_MANAGER,
                Authority.ROLE_SELLER,
                Authority.ROLE_FINANCIAL,
              ]) && (
                <CustomButton onClick={() => handleDisplayCreditApprovalModals(stepFinance?.isConfirmed)}>
                  {t('admin.customerAnalysis.approveCredit')}
                </CustomButton>
              )}
            {bankAccountInfos?.bankAccount == null &&
              bankAccountInfos?.financingPaymentType === FinancingPaymentType.CLIENT &&
              stepFinance?.isConfirmed &&
              hasAuthorities(account?.authorities, [
                Authority.ROLE_ADMIN,
                Authority.ROLE_INTERNAL_ADMIN,
                Authority.ROLE_MANAGER,
                Authority.ROLE_SELLER,
                Authority.ROLE_FINANCIAL,
                Authority.ROLE_ANALYST,
              ]) && (
                <CustomButton onClick={() => setIsShowModalCreateBankAccount(true)}>
                  {t('admin.customerAnalysis.addBankDetails')}
                </CustomButton>
              )}
            {financeStatus != null &&
              financeStatus !== FinancingStatusType.CANCELLED &&
              financeStatus !== FinancingStatusType.FINISHED &&
              financeStatus !== FinancingStatusType.PENDING_PAYMENT &&
              financeStatus !== FinancingStatusType.PENDING_SIGN &&
              hasAuthorities(account?.authorities, [
                Authority.ROLE_ADMIN,
                Authority.ROLE_INTERNAL_ADMIN,
                Authority.ROLE_MANAGER,
                Authority.ROLE_SELLER,
              ]) && (
                <CustomButton className="cancel" onClick={() => setShowCancelModal(true)}>
                  {t('admin.customerAnalysis.cancelFinancing')}
                </CustomButton>
              )}
            {financeStatus != null &&
              (financeStatus === FinancingStatusType.FINISHED ||
                financeStatus === FinancingStatusType.PENDING_PAYMENT ||
                financeStatus === FinancingStatusType.PENDING_SIGN) &&
              hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
                <CustomButton className="cancel" onClick={() => setShowCancelModal(true)}>
                  {t('admin.customerAnalysis.cancelFinancing')}
                </CustomButton>
              )}
            {financeStatus != null &&
              (financeStatus === FinancingStatusType.VALIDATION || financeStatus === FinancingStatusType.ADJUSTED) &&
              hasAuthorities(account?.authorities, [
                Authority.ROLE_ADMIN,
                Authority.ROLE_MANAGER,
                Authority.ROLE_SELLER,
                Authority.ROLE_FINANCIAL,
                Authority.ROLE_INTERNAL_ADMIN,
              ]) &&
              clientData?.registrationStatus === RegistrationStatus.APPROVED && (
                <CustomButton onClick={() => setIsShowAnalyzeFinancingModal(true)}>{t('admin.customerAnalysis.buttonText')}</CustomButton>
              )}
          </ButtonsContainer>
        </TitleHeader>
        <StatusContainer>
          <Status text={t(`enum.filterAllStatus.${financeStatus}`)} color={financeStatus ?? ''} />
          {isHcred && <Status text={t(`enum.financingStatus.${financeHcredStatus ?? 'UNKNOWN'}`)} color={financeStatus ?? ''} />}
        </StatusContainer>
        <StepBarFinanceProgress steps={systemSteps} finance={finance} verifyIdentity={verifyIdentity} />
        <TabContainer>
          <TabHeaderContainer>
            {clientId != null && clientData?.physicalPerson?.name != null && (
              <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
                <TabTitleHeader>{t('admin.procedureScreen.clientName')}</TabTitleHeader>
                <TabClientName onClick={() => history.push(`/admin/cadastros/${clientId}`)}>{clientData.physicalPerson.name}</TabClientName>
              </TabHeaderInnerContainer>
            )}
            <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
              <TabTitleHeader>{t('admin.procedureScreen.registerStatus')}</TabTitleHeader>
              <Status text={t(`enum.filterAllStatus.${clientData?.registrationStatus}`)} color={clientData?.registrationStatus ?? ''} />
            </TabHeaderInnerContainer>
            {bankAccountInfos?.financingPaymentType != null && !isHcred && (
              <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
                <TabTitleHeader>{t('admin.procedureScreen.paymentFor')}</TabTitleHeader>
                <Status
                  text={formatPaymentTypeToShow(bankAccountInfos.financingPaymentType)}
                  color={bankAccountInfos.financingPaymentType}
                />
              </TabHeaderInnerContainer>
            )}
            {contestationStatus != null && (
              <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
                <TabTitleHeader>{t('admin.procedureScreen.contestationStatus')}</TabTitleHeader>
                <Status text={StringUtils.contestationStatusText(contestationStatus) ?? ''} color={FinancingPaymentType.ORGANIZATION} />
              </TabHeaderInnerContainer>
            )}
            {contracts[0]?.status === ContractStatus.FINISHED && (
              <TabHeaderInnerContainer style={{ borderRight: '1px solid #E5E7E7', paddingRight: '16px', marginRight: '16px' }}>
                <TabTitleHeader>{t('admin.procedureScreen.paymentStatus')}</TabTitleHeader>
                <Status
                  text={finance?.isPaid ? t('admin.procedureScreen.confirmed') : t('admin.procedureScreen.pending')}
                  color={FinancingPaymentType.CLIENT}
                />
              </TabHeaderInnerContainer>
            )}
            {cieloStatus?.returnCode != null && (
              <TabHeaderInnerContainer>
                <TabTitleHeader>{t('admin.procedureScreen.processingErrorCielo')}</TabTitleHeader>
                <Status
                  text={`${cieloStatus.returnCode} - ${cieloStatus.returnMessage}`}
                  color={cieloStatus.returnCode !== CodeStatusCielio.SUCCESS ? FinancingStatus.REJECTED : FinancingStatus.APPROVED}
                />
              </TabHeaderInnerContainer>
            )}
            {(clientData?.registrationStatus === RegistrationStatus.ADJUST ||
              clientData?.registrationStatus === RegistrationStatus.ADJUSTED ||
              clientData?.registrationStatus === RegistrationStatus.APPROVED ||
              clientData?.registrationStatus === RegistrationStatus.REJECTED) && (
              <>
                <AddAnalistButton onClick={() => setShowAnalisysInfoModal(true)} theme={theme}>
                  <EyeButton src={Eye} />
                  {t('clientData.seeAnalysis')}
                </AddAnalistButton>
              </>
            )}
          </TabHeaderContainer>
          <TabBox>
            <Tab $isSelected={tabSelected.tabNumber === 1} onClick={() => setTabSelected({ tabNumber: 1 })}>
              <span>{t('admin.customerAnalysis.tabs.details')}</span>
            </Tab>
            {systemSteps.map((step, index) => {
              if (tabsSystemSteps.includes(step)) {
                return (
                  <Tab
                    key={index}
                    $isSelected={tabSelected.tabNumber === index + 2}
                    onClick={() => setTabSelected({ tabNumber: index + 2, tabStep: step })}
                  >
                    <span>{t(`admin.customerAnalysis.tabs.${step}`)}</span>
                  </Tab>
                );
              }
            })}
          </TabBox>
          <TabContent>{getTabContent()}</TabContent>
        </TabContainer>
        <CardsContainer>
          {getCardsForDashboard()?.map(card => {
            if (card)
              return (
                <CardForDashboard
                  key={card.key}
                  isActive={card.isActive}
                  hide={card.hide}
                  icon={card.icon ?? <></>}
                  title={card.title ?? ''}
                  content={card.content ?? <></>}
                  flexCard
                  arrowButton
                  onHandleClick={() => {
                    const redirect = card.redirectTo;
                    if (redirect) {
                      if (card.redirectPayload) {
                        history.push(redirect, { payload: card.redirectPayload, prevPath: history.location.pathname });
                      } else {
                        history.push(redirect, { prevPath: history.location.pathname });
                      }
                    }
                  }}
                />
              );
          })}
        </CardsContainer>
      </PageContainer>

      <ModalAnalyzingCredit showModal={isShowAnalyzeFinancingModal} closeModal={() => setIsShowAnalyzeFinancingModal(false)} />

      {showCancelModal && (
        <CustomModalTwoButtons
          isShowModal={showCancelModal}
          onCloseModal={closeModal}
          firstButtonText={t('global.button.cancel')}
          secondButtonText={t('global.button.confirm')}
          handleClickFirstButton={closeModal}
          handleClickSecondButton={cancelFinancing}
          title={t('admin.customerAnalysis.cancelFinancingTitle')}
          large
          loading={loading}
        />
      )}

      <CustomModalTwoButtons isShowModal={isShowSimulatorModal} onCloseModal={() => setIsShowSimulatorModal(false)}>
        <SimulatorDashboard
          isAdminSimulator
          handleCloseModal={handleConfirmFinancing}
          financingId={Number(financingId)}
          isConfirmationButtonIsLoading={isConfirmationButtonIsLoading}
        />
      </CustomModalTwoButtons>

      <CustomModalTwoButtons
        formId="bank-account-creation-form"
        large
        isShowModal={isShowModalCreateBankAccount}
        onCloseModal={() => {
          setIsShowModalCreateBankAccount(false);
          setIsLoadingBankAccount(false);
        }}
        title={t('dashboard.addBankDetails')}
        children={
          ORGANIZATION_NAME !== OrganizationName.ZM ? (
            <BankDetailsRegistrationFormPix
              props={{
                clientData: clientData?.physicalPerson,
                selectedBankAccount,
                isRegistrationByAdmin: true,
                clientId,
                financingId: Number(financingId),
                setSelectedBankAccount,
                setIsShowModalCreateBankAccount,
                setIsLoadingBankAccount,
                getBankAccount: () => {
                  getFinancingCode();
                  getBankAccount();
                  getAllContractsFinancing();
                },
              }}
            />
          ) : (
            <BankAccountForm
              isRegistrationByAdmin
              selectedBankAccount={selectedBankAccount}
              setSelectedBankAccount={setSelectedBankAccount}
              setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
              setIsLoadingBankAccount={setIsLoadingBankAccount}
              clientData={clientData?.physicalPerson}
              clientId={clientId}
              financingId={Number(financingId)}
              getBankAccount={() => {
                getFinancingCode();
                getBankAccount();
                getAllContractsFinancing();
              }}
            />
          )
        }
        secondButtonText={selectedBankAccount?.id != null ? t('global.button.save') : t('global.button.add')}
        handleClickFirstButton={() => setIsShowModalCreateBankAccount(false)}
        loading={isLoadingBankAccount}
        firstButtonText={t('global.button.cancel')}
      />
    </>
  );
};
