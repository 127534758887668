import CardShowContract from 'components/card-show-contract';
import MobileHeader from 'components/mobile-header';
import CustomButton from 'components/custom-button';
import {
  ArrowsImg,
  CaptureButtonsContainer,
  CircleButton,
  PageTitleSection,
  StyledButton,
  StyledContainer,
  StyledInnerContainer,
} from '../styles';
import { StyledCardShowContractContainer, DocumentContainer } from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import ContractContext from 'context/contract';
import Webcam from 'react-webcam';
import contractService from 'services/contract-service';
import IOcr, { IOcrAttachments } from 'model/ocr';
import StringUtils from 'shared/util/string-utils';
import { OcrAttachmentType } from 'model/enums/ocr-type';
import { RoutePath } from 'model/enums/route-path';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import TwoArrows from '../../../images/two-circular-arrows-symbol-in-a-circle.png';
import { ArrowContainer } from '../go-to-mobile/styles';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import { isIOS } from 'react-device-detect';
import { isDeviceIOS } from 'shared/util/utils';
import { IdentityDocumentTypes } from 'model/enums/identity-document-type';
import FaceMatch from 'model/enums/face-match';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import SystemStepCategory from 'model/enums/system-step-category';

const TakeDocument = ({ t }: WithTranslation) => {
  const history = useHistory();

  let documentRef = useRef<Webcam | null>(null);

  const { documentPresignUrl, signatureKey, personSignatureId, identityDocument, contractResponse, setContractResponse } =
    useContext(ContractContext);

  const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
  const [isBack, setIsBack] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(
    identityDocument === IdentityDocumentTypes.RG || IdentityDocumentTypes.OTHER
      ? 'contract.takeDocument.frontTitle'
      : 'contract.takeDocument.frontTitleCnh'
  );
  const [subtitle, setSubtitle] = useState<string>(
    identityDocument === IdentityDocumentTypes.RG || IdentityDocumentTypes.OTHER
      ? 'contract.takeDocument.frontSubtitle'
      : 'contract.takeDocument.frontSubtitleCnh'
  );
  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const [isShowButtons, setIsShowButtons] = useState<boolean>(false);
  const [documentFrontPic, setDocumentFrontPic] = useState<IOcrAttachments | null>();
  const [documentVersePic, setDocumentVersePic] = useState<IOcrAttachments | null>();
  const [picScreen, setPicScreen] = useState<string>('');
  const { isMobile, isTablet } = useResponsiveMobile();
  const [frontCamera, setFrontCamera] = useState<boolean>(false);
  const [signatoryDocument, setSignatoryDocument] = useState<string>();

  const [orgStep, setOrgStep] = useState<SystemStepCategory[]>([]);

  const handleGetContract = async () => {
    const response = await contractService
      .getContract(`${signatureKey}`)
      .then(response => {
        if (response) {
          setContractResponse(response);
          setSignatoryDocument(response.contractSignatory?.signatory?.documentNumber);
          return response;
        }
      })
      .catch(() => {
        history.push(RoutePath.ERROR_ANALYSIS);
        return null;
      });

    return response;
  };

  const handleSendOcr = (orcData: IOcr) => {
    contractService
      .sendOcr(orcData)
      .then(() =>
        handleGetContract().then(response => {
          if (response?.isDocumentOcrDone) {
            const goToNextStepIn = 2300;
            const isMustCreateFaceMatch =
              !contractResponse?.isFaceMatchDone &&
              contractResponse?.faceMatchedProcessingTries === 0 &&
              orgStep.includes(SystemStepCategory.FRAUD_ANALYSIS_SELFIE);

            history.push(RoutePath.STEP_COMPLETED);

            setTimeout(() => {
              if (isMustCreateFaceMatch) {
                history.push(RoutePath.SIGNATURE_ANALYSIS);
                handleCreateFaceMatch({ id: contractResponse?.id });
              } else {
                history.push(RoutePath.SIGNATURE_SUCCESS);
              }
            }, goToNextStepIn);
          } else {
            history.push(RoutePath.SIGNATURE_RELEASE);
          }
        })
      )
      .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
  };

  const handleGetSystemSteps = () => {
    organizationsSystemStepsService
      .getSteps()
      .then(response => setOrgStep(response.map(systemSteps => systemSteps.systemStep.step)))
      .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
  };

  const handleCreateFaceMatch = (data: FaceMatch) => {
    contractService
      .createFaceMatch(data)
      .then(() =>
        handleGetContract().then(response => {
          if (response?.isFaceMatchDone) {
            const goToNextStepIn = 2300;

            history.push(RoutePath.STEP_COMPLETED);

            setTimeout(() => {
              history.push(RoutePath.SIGNATURE_SUCCESS);
            }, goToNextStepIn);
          } else {
            history.push(RoutePath.SIGNATURE_RELEASE);
          }
        })
      )
      .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
  };

  const changeCamera = () => {
    setFrontCamera(!frontCamera);
  };

  const retakeDocumentPic = () => {
    setIsShowButtons(false);
    if (!isBack) {
      setDocumentFrontPic(null);
    } else {
      setDocumentVersePic(null);
    }
  };

  const submitDocuments = () => {
    setIsShowButtons(false);
    if (!isBack && (identityDocument === IdentityDocumentTypes.RG || identityDocument === IdentityDocumentTypes.OTHER)) {
      setIsBack(true);
      documentRef.current = null;
      setPicScreen('');
      return;
    }

    if (identityDocument === IdentityDocumentTypes.CNH && documentFrontPic != null) {
      if (personSignatureId != null) {
        const orcData: IOcr = {
          id: personSignatureId,
          documentOcr: {
            attachments: [documentFrontPic],
          },
        };

        if (!contractResponse?.isDocumentOcrDone && contractResponse?.canRetry) {
          history.push(RoutePath.DOCUMENT_ANALYSIS);
          handleSendOcr(orcData);
        }
      }

      return;
    }

    if (isReadyToSubmit && documentFrontPic != null && documentVersePic != null) {
      const attachmentsData = [documentFrontPic, documentVersePic];

      if (personSignatureId != null) {
        const orcData: IOcr = {
          id: personSignatureId,
          documentOcr: {
            attachments: attachmentsData,
          },
        };

        if (!contractResponse?.isDocumentOcrDone && contractResponse?.canRetry) {
          history.push(RoutePath.DOCUMENT_ANALYSIS);
          handleSendOcr(orcData);
        }
      }
    }
  };

  useEffect(() => {
    if (isBack && (identityDocument === IdentityDocumentTypes.RG || identityDocument === IdentityDocumentTypes.OTHER)) {
      setTitle('contract.takeDocument.backTitle');
      setSubtitle('contract.takeDocument.backSubtitle');
      return;
    }
  }, [isBack]);

  useEffect(() => {
    if (!signatureKey) {
      return history.push(RoutePath.HOME);
    }

    if (signatureKey !== null) {
      if (!isMobile && !isTablet) {
        return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
      }

      handleGetContract();
      handleGetSystemSteps();
    }
  }, [signatureKey, isMobile, isTablet]);

  useEffect(() => {
    if (documentVersePic != null) {
      setIsReadyToSubmit(true);
    }
  }, [documentFrontPic, setDocumentFrontPic, documentVersePic, setDocumentVersePic]);

  const getDevicePixelRatio = (): number => {
    let mediaQuery;
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      mediaQuery =
        '(-webkit-min-device-pixel-ratio: 2),\
      (min-resolution: 2dppx)';
      if (window.matchMedia(mediaQuery).matches) {
        return 2;
      }
    }

    if (window.devicePixelRatio !== undefined && !isFirefox) {
      return window.devicePixelRatio;
    } else if (window.matchMedia) {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 2),\
        (min-resolution: 2dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 2;
        }
      } else {
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 1.5),\
      (min--moz-device-pixel-ratio: 1.5),\
      (-o-min-device-pixel-ratio: 3/2),\
      (min-resolution: 1.5dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 1.5;
        }
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 2),\
      (min--moz-device-pixel-ratio: 2),\
      (-o-min-device-pixel-ratio: 2/1),\
      (min-resolution: 2dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 2;
        }
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 0.75),\
      (min--moz-device-pixel-ratio: 0.75),\
      (-o-min-device-pixel-ratio: 3/4),\
      (min-resolution: 0.75dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 1.5;
        }
      }
    }
    return 1;
  };

  async function takePhoto() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: frontCamera ? 'user' : { exact: 'environment' },
        },
      });
      const videoTrack = stream.getVideoTracks()[0];
      const settings = videoTrack.getSettings();
      const videoElement = document.createElement('video');
      videoElement.srcObject = stream;
      videoElement.setAttribute('muted', '');
      videoElement.playsInline = true;
      await videoElement.play();

      let canvasBackground = document.createElement('canvas');
      let width = (settings.width ?? 0) * getDevicePixelRatio();
      let height = (settings.height ?? 0) * getDevicePixelRatio();

      if (isIOS || isDeviceIOS()) {
        width = Math.min(settings.width ?? 0, settings.height ?? 0);
        height = Math.max(settings.width ?? 0, settings.height ?? 0);
      }

      canvasBackground.width = width;
      canvasBackground.height = height;

      canvasBackground.style.display = 'none';

      const context = canvasBackground.getContext('2d');

      canvasBackground.style.display = 'none;';
      if (context) {
        context.drawImage(videoElement, 0, 0, canvasBackground.width, canvasBackground.height);
        context.fillStyle = 'rgb(71,84,68)';
        context.fillRect(20, 50, 1, 1);
        context.fillStyle = 'rgb(211,190,124)';
        context.fillRect(422, 522, 1, 1);
        const pictureData = context?.getImageData(0, 0, canvasBackground.width, canvasBackground.height);
        context.putImageData(pictureData, 0, 0);
      }

      const photoURL = canvasBackground.toDataURL('image/png');

      if (documentRef.current) {
        if (photoURL !== null && signatoryDocument != null && !isBack) {
          const frontPic = StringUtils.getCaptureAttachment(photoURL, signatoryDocument, isBack, OcrAttachmentType.OCR_DOCUMENT_FRONT);
          setDocumentFrontPic(frontPic);
          setPicScreen(photoURL);
          setIsShowButtons(true);
        } else if (photoURL !== null && signatoryDocument != null && isBack) {
          const versePic = StringUtils.getCaptureAttachment(photoURL, signatoryDocument, isBack, OcrAttachmentType.OCR_DOCUMENT_BACK);
          setDocumentVersePic(versePic);
          setPicScreen(photoURL);
          setIsShowButtons(true);
        } else {
          console.error('Erro ao capturar foto do documento');
        }
      }

      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.error('Erro ao tirar foto:', error);
    }
  }

  return (
    <StyledContainer>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(`/lets-sign`)} />
            <span>{t('letsSign.stepOfTotal', { step: '3' })}</span>
          </ArrowContainer>
          <p>{t(title)}</p>
          <span>{t(subtitle)}</span>
        </PageTitleSection>
        <DocumentContainer $increaseHeight={identityDocument === IdentityDocumentTypes.CNH}>
          {!documentFrontPic ? (
            <>
              <Webcam
                screenshotQuality={1}
                videoConstraints={{
                  facingMode: frontCamera ? 'user' : { exact: 'environment' },
                }}
                style={frontCamera ? { transform: 'scaleX(-1)' } : {}}
                width={'100%'}
                audio={false}
                ref={documentRef}
                screenshotFormat="image/png"
              />
              <div className="document-frame"></div>
            </>
          ) : !documentVersePic && isBack ? (
            <>
              <Webcam
                screenshotQuality={1}
                videoConstraints={{
                  facingMode: frontCamera ? 'user' : { exact: 'environment' },
                }}
                style={frontCamera ? { transform: 'scaleX(-1)' } : {}}
                width={'100%'}
                audio={false}
                ref={documentRef}
                screenshotFormat="image/png"
              />
              <div className="document-frame"></div>
            </>
          ) : (
            <img src={picScreen} style={{ maxWidth: '-webkit-fill-available', objectFit: 'contain' }} alt="foto do documento" />
          )}
        </DocumentContainer>
        {((!documentVersePic && isBack) || !documentFrontPic) && (
          <CaptureButtonsContainer>
            <CircleButton onClick={() => takePhoto()} />
            <ArrowsImg src={TwoArrows} onClick={() => changeCamera()} />
          </CaptureButtonsContainer>
        )}
        <StyledButton></StyledButton>
        {isShowButtons && (
          <StyledButton>
            <CustomButton isInvertColor className="button-mobile" onClick={() => retakeDocumentPic()}>
              {t('contract.takeDocument.takePicAgain')}
            </CustomButton>
            <CustomButton className="button-mobile" onClick={() => submitDocuments()}>
              {t('global.button.continue')}
            </CustomButton>
          </StyledButton>
        )}
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(TakeDocument);
