import { yupResolver } from '@hookform/resolvers/yup';
import PreRegisterContext from 'context/pre-register';
import Translate from 'i18n/translate';
import _ from 'lodash';
import { FormProperties } from 'model/enums/form-properties';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import { SimulatorFormData } from 'model/landing-page';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authenticationService from 'services/authentication-service';
import clientDataService from 'services/client-data-service';
import clientService from 'services/client-service';
import StringUtils, { validateCNPJ, validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { PaginationItem } from '../../../style';
import SimulatorButton from '../form-button';
import { PaginationBox, SimulatorContainer, SimulatorText, SimulatorTitle, StyledForm, Terms } from '../../style';
import InputText from 'components/styled-input';
import { Mask } from 'model/mask-types';
import { SimulatorProcessingMoment, SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';
import { FinancingSimpleSimulation } from 'model/financing';

interface FormPrimaryProps {
  steps: string[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setIsLoading: (value: number) => void;
  formData: SimulatorFormData;
  setFormData: (data: SimulatorFormData) => void;
  initialForm: SimulatorFormData;
  isPhysicalPerson?: boolean;
  isLoading: number;
  sendApi: (data: SimulatorFormData) => void;
  isAdmin?: boolean;
}
const FormPrimary: React.FC<FormPrimaryProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsLoading,
  formData,
  setFormData,
  isPhysicalPerson,
  isLoading,
  sendApi,
  isAdmin = false,
}) => {
  const { setRegistrationKey, registrationKey } = useContext(PreRegisterContext);
  const history = useHistory();
  const { t } = useTranslation();
  const isLast = currentStep === steps.length - 1;

  const schema = yup.object().shape({
    [FormProperties.NAME]: yup.string().required('Campo obrigatório').min(3, 'deve ter no mínimo 3 caracteres'),
    [FormProperties.PHONE]: yup.string().required('Campo obrigatório'),
    [FormProperties.EMAIL]: yup.string().required('Campo obrigatório').email('Email inválido'),
    [isPhysicalPerson ? FormProperties.CPF : FormProperties.CNPJ]: yup
      .string()
      .test(
        `test-${isPhysicalPerson ? FormProperties.CPF : FormProperties.CNPJ}`,
        isPhysicalPerson ? t('global.errorMessage.invalidCpf') : t('global.errorMessage.invalidCnpj'),
        numbers => (isPhysicalPerson ? validateCpf(numbers!) : validateCNPJ(numbers!))
      )
      .required('Campo obrigatório'),
  });
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleForm = async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }
    if (isLast) {
      setIsLoading(1);
      sendApi(formData);

      setTimeout(() => {
        setIsLoading(0);
      }, 2500);
    } else {
      setIsLoading(1);

      const getDocumentNumber = StringUtils.removeNonNumbersFromMaskedValue(
        formData[isPhysicalPerson ? FormProperties.CPF : FormProperties.CNPJ] ?? ''
      );

      clientService
        .getBasicInfo(getDocumentNumber)
        .then(basicInfo => {
          if (basicInfo.isActive === false) {
            return history.push(RoutePath.INACTIVE_ACCOUNT);
          }

          const isEmpty = Object.keys(basicInfo).length === 0;

          switch (basicInfo.registrationStatus) {
            case RegistrationStatus.NEW:
              authenticationService
                .checkIfClientAlreadyRegistered(StringUtils.removeNonNumbersFromMaskedValue(getDocumentNumber ?? ''))
                .then(async response => {
                  if (response.isRegistered) {
                    if (isAdmin) {
                      const clientData = await clientDataService.getClientSummary(basicInfo.id);
                      history.push(`/selfie/${clientData?.id}/admin/qrcode/${clientData?.selfieKey}/`);
                    } else {
                      return history.push({
                        pathname: '/login/authenticate',
                        state: { documentValue: getDocumentNumber },
                      });
                    }
                  } else {
                    localStorage.setItem(LocalStorageKeys.REGISTRATION_KEY, JSON.stringify(basicInfo?.registrationKey));
                    setRegistrationKey(basicInfo?.registrationKey);

                    const lastSimulation = basicInfo?.finances?.[0];

                    const sendSimulator: FinancingSimpleSimulation = {
                      choosedValue: lastSimulation?.choosedValue,
                      client: { id: basicInfo?.id },
                      id: lastSimulation?.id,
                      installmentsTotal: lastSimulation?.installmentsTotal,
                    };
                    return history.push(RoutePath.FOUNDED_DATA, {
                      sendSimulator: sendSimulator,
                    } as SimulatorState);
                  }
                });
              return null;
            case RegistrationStatus.PRE_REJECTED:
              return history.push(RoutePath.SIMULATOR_REPROVED);
            case RegistrationStatus.REJECTED:
              return history.push(RoutePath.SIMULATOR_REPROVED);
            default:
              if (isEmpty) {
                return setCurrentStep(currentStep + 1);
              } else {
                authenticationService.checkIfClientAlreadyRegistered(getDocumentNumber).then(resRegister => {
                  if (resRegister.isRegistered && isAdmin) {
                    return history.push({
                      pathname: `/admin/dados-encontrados-admin`,
                      state: { documentValue: getDocumentNumber, id: basicInfo?.id },
                    });
                  }
                  if (resRegister.isRegistered) {
                    return history.push({
                      pathname: '/login/authenticate',
                      state: { documentValue: getDocumentNumber },
                    });
                  }
                });
              }
              return;
          }
        })
        .finally(() => setIsLoading(0));
    }
  };
  const handleSubmit = _.debounce(handleForm, 500);

  const formId: string = 'form-prymary';

  const onHandleChangeStep = async (step: number) => {
    const valid = await methods.trigger();
    if (valid || step < currentStep) {
      setCurrentStep(step);
    }
  };

  const handleChange = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <>
      <SimulatorContainer>
        <SimulatorTitle>{t('landingPage.openingScreen.writeYourData')}</SimulatorTitle>
        <FormProvider {...methods}>
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            name="LP - Form Completo"
            id={formId}
          >
            {isPhysicalPerson ? (
              <InputText
                name={FormProperties.NAME}
                isRequired
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.name')}
                onChange={e => handleChange(e.target.value, FormProperties.NAME)}
              />
            ) : (
              <InputText
                name={FormProperties.NAME}
                isRequired
                onChange={e => handleChange(e.target.value, FormProperties.NAME)}
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.corporateName')}
              />
            )}
            {isPhysicalPerson ? (
              <InputText
                name={FormProperties.CPF}
                onChange={e => handleChange(e.target.value, FormProperties.CPF)}
                isRequired
                mask={Mask.CPF}
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.cpf')}
                maxLength={14}
              />
            ) : (
              <InputText
                name={FormProperties.CNPJ}
                isRequired
                onChange={e => handleChange(e.target.value, FormProperties.CNPJ)}
                label={t('landingPage.openingScreen.simulatorForm.inputLabels.cnpj')}
                mask={Mask.CNPJ}
                maxLength={18}
              />
            )}
            <InputText
              name={FormProperties.EMAIL}
              isRequired
              onChange={e => handleChange(e.target.value, FormProperties.EMAIL)}
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.email')}
            />
            <InputText
              name={FormProperties.PHONE}
              isRequired
              onChange={e => handleChange(StringUtils.phoneMask(e.target.value), FormProperties.PHONE)}
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.whatsApp')}
              maxLength={15}
              mask={Mask.PHONE}
            />
          </StyledForm>
        </FormProvider>
        {!isAdmin && (
          <Terms>
            {t('landingPage.openingScreen.simulatorSlider.mensTerm')}
            <a href="https://esparta.s3.amazonaws.com/Termos+de+Uso+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.termsOfUse')}
            </a>
            {t('landingPage.openingScreen.simulatorSlider.and')}
            <a href="https://esparta.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.privacyPolicy')}
            </a>
          </Terms>
        )}
        <SimulatorButton isLast={isLast} currentStep={currentStep} setCurrentStep={setCurrentStep} isLoading={isLoading} formId={formId} />
      </SimulatorContainer>
    </>
  );
};

export default FormPrimary;
