import configuration from './custom-organization/config';

export const AUTHORITIES = {
  SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  INTERNAL: 'ROLE_INTERNAL',
  INTERNAL_ADMIN: 'ROLE_INTERNAL_ADMIN',
  PARTNER: 'ROLE_PARTNER',
  USER: 'USER',
};

/**
 * DATE CONSTANTS
 */
export const APP_DATE_FORMAT: string = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT: string = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT: string = 'dd/MM/yy';
export const APP_LOCAL_ISO_FORMAT: string = 'yyyy-MM-dd';
export const APP_LOCAL_ISO_FORMAT_Z: string = 'YYYY-MM-DDTHH:mm Z';
export const APP_DATE_TIME: string = 'DD/MM/YYYY - HH:mm:ss';

/**
 * URLS
 */
export const SERVER_API_URL: string | undefined = process.env.REACT_APP_API_URL;

/**
 * ORGANIZATION
 */

export const ORGANIZATION_NAME: string | undefined = process.env.REACT_APP_ORGANIZATION_NAME;
export const ORGANIZATION_TYPE: string | undefined = process.env.REACT_APP_ORGANIZATION_TYPE;
export const NO_LANDING_PAGE: boolean = process.env.REACT_APP_NO_LANDING_PAGE ? Boolean(process.env.REACT_APP_NO_LANDING_PAGE) : false;

/**
 * LOCALE CONSTANTS
 */
export const DEFAULT_LANGUAGE: string = configuration?.localization?.i18n?.default_language ?? 'en';
export const MOMENT_LOCALE: string = configuration?.localization?.moment?.locale ?? 'en-us';
export const INTL_LOCALE: string = configuration?.localization?.Intl?.locale ?? 'en-US';
export const INTL_CURRENCY: string = configuration?.localization?.Intl?.currency ?? 'USD';

export const PHONE_REGEX: RegExp = configuration?.format?.phone?.regex ?? /.*/;

/**
 * FILE CONSTANTS
 */
export const APP_IMAGE_SIZE_LIMIT: number = 15555555;
