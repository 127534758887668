import MobileHeader from 'components/mobile-header';
import InstructionCard from 'components/instruction-card';
import { ReactComponent as ConfirmDataIconSvg } from './../../../images/user.svg';
import { ReactComponent as DocIconSvg } from './../../../images/doc.svg';
import { ReactComponent as CameraVideoIconSvg } from './../../../images/video-camera.svg';
import { ReactComponent as SimpleCameraIconSvg } from './../../../images/camera.svg';
import CustomButton from 'components/custom-button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import { PageTitleSection, StyledCardShowContractContainer, StyledCardsContainer, StyledContainer, StyledInnerContainer } from '../styles';
import { useContext, useEffect, useState } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import ContractContext from 'context/contract';
import contractService from 'services/contract-service';
import { OrgStepType } from 'model/enums/org-system-step-type';
import { RoutePath } from 'model/enums/route-path';
import { PersonSignatureStatusTypes } from 'model/enums/person-signature-status';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import SystemStepCategory from 'model/enums/system-step-category';

const LetsSign = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { documentPresignUrl, signatureKey } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [isConfirmedData, setIsConfirmedData] = useState<boolean>(false);
  const [isDocumentOcrDone, setIsDocumentOcrDone] = useState<boolean>(false);
  const [isLivenessDone, setIsLivenessDone] = useState<boolean>(false);
  const [isFaceMatchDone, setIsFaceMatchDone] = useState<boolean>(false);
  const [ocrProcessingTries, setOcrProcessingTries] = useState<number>(0);
  const [livenessProcessingTries, setLivenessProcessingTries] = useState<number>(0);
  const [faceMatchedProcessingTries, setFaceMatchedProcessingTries] = useState<number>(0);
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const [personSignatureStatus, setPersonSignatureStatus] = useState<PersonSignatureStatusTypes>();

  const handleNextStep = () => {
    if (personSignatureStatus === PersonSignatureStatusTypes.DONE) {
      return history.push(RoutePath.SIGNATURE_SUCCESS);
    }

    if (personSignatureStatus === PersonSignatureStatusTypes.CANCELED) {
      return history.push(RoutePath.SIGNATURE_CANCELED);
    }

    if (isConfirmedData && !isLivenessDone) {
      if (livenessProcessingTries > 0 && !canRetry) {
        return history.push(RoutePath.SIGNATURE_RELEASE);
      }

      return history.push(RoutePath.PROOF_LIFE);
    }

    if (isConfirmedData && isLivenessDone && !isDocumentOcrDone) {
      if (ocrProcessingTries > 0 && !canRetry) {
        return history.push(RoutePath.SIGNATURE_RELEASE);
      }

      return history.push(RoutePath.REGISTER_DOCUMENT);
    }

    if (isConfirmedData && isLivenessDone && isDocumentOcrDone && !isFaceMatchDone) {
      if (faceMatchedProcessingTries > 0 && !canRetry) {
        return history.push(RoutePath.SIGNATURE_RELEASE);
      }

      return history.push(RoutePath.SELFIE_INSTRUCTIONS);
    }

    return history.push(RoutePath.CONFIRM_DATA);
  };

  const renderSteps = () => {
    return steps.map((oneStep, key) => {
      if (oneStep.systemStep.step === SystemStepCategory.SIGNATURE_DATA) {
        return (
          <div key={`Instruction-card-${key}`}>
            <InstructionCard
              icon={<ConfirmDataIconSvg />}
              title={'Confirme seus dados'}
              text={'Nome, CPF e data de nascimento.'}
              isDone={isConfirmedData}
            />
          </div>
        );
      }
      if (oneStep.systemStep.step === SystemStepCategory.DOC_FRAUD_ANALYSIS) {
        return (
          <div key={`Instruction-card-${key}`}>
            <InstructionCard icon={<DocIconSvg />} title={'Documentação'} text={'Verificação de documento'} isDone={isDocumentOcrDone} />
          </div>
        );
      }
      if (oneStep.systemStep.step === SystemStepCategory.LIVENESS) {
        return (
          <div key={`Instruction-card-${key}`}>
            <InstructionCard
              icon={<CameraVideoIconSvg />}
              title={'Liveness'}
              text={'Confirmação de prova de vida'}
              isDone={isLivenessDone}
            />
          </div>
        );
      }
      if (oneStep.systemStep.step === SystemStepCategory.FRAUD_ANALYSIS_SELFIE) {
        return (
          <div key={`Instruction-card-${key}`}>
            <InstructionCard
              icon={<SimpleCameraIconSvg />}
              title={'Face Match'}
              text={'Confirmação de identidade'}
              isDone={isFaceMatchDone}
            />
          </div>
        );
      }
    });
  };

  useEffect(() => {
    organizationSystemStepsService.getSteps().then(response => {
      if (response) {
        setSteps(response);
      }
    });

    contractService.getContract(`${signatureKey}`).then(response => {
      if (response) {
        setIsConfirmedData(response.isConfirmedData ?? false);
        setIsDocumentOcrDone(response.isDocumentOcrDone ?? false);
        setIsLivenessDone(response.isLivenessDone ?? false);
        setIsFaceMatchDone(response.isFaceMatchDone ?? false);
        setCanRetry(response.canRetry ?? false);
        setOcrProcessingTries(response.ocrProcessingTries ?? 0);
        setLivenessProcessingTries(response.livenessProcessingTries ?? 0);
        setFaceMatchedProcessingTries(response.faceMatchedProcessingTries ?? 0);
        setPersonSignatureStatus(response.personSignatureStatus);
      }
    });
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }
    if (!signatureKey) {
      return history.push('/');
    }
  }, []);

  return (
    <StyledContainer>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <PageTitleSection>
          <p>{t('letsSign.title')}</p>
          <span>{t('letsSign.subtitle')}</span>
        </PageTitleSection>
        <StyledCardsContainer>
          {renderSteps()}
          <CustomButton className="mobile-button--gofoward" disabled={false} onClick={() => handleNextStep()}>
            {t('global.button.nextStep')}
          </CustomButton>
        </StyledCardsContainer>
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default LetsSign;
