import { StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';
import { WaitRegistrationValidationSvg } from 'images/wait-registration-validation';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import contractService from 'services/contract-service';
import { RoutePath } from 'model/enums/route-path';
import { PersonSignatureStatusTypes } from 'model/enums/person-signature-status';

const WaitNewLiberation = ({ t }: WithTranslation) => {
  const history = useHistory();
  const [subtitle, setSubtitle] = useState<string>(t('contract.waitingStep.waitNewLiberation.subtitle'));
  const { isMobile, isTablet } = useResponsiveMobile();
  const { signatureKey } = useContext(ContractContext);

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }
  }, []);

  useEffect(() => {
    contractService
      .getContract(`${signatureKey}`)
      .then(response => {
        switch (response.personSignatureStatus) {
          case PersonSignatureStatusTypes.ERROR_ANTI_FRAUD_OCR:
            setSubtitle(t('contract.waitingStep.waitNewLiberation.subtitleOCR'));
            break;
          case PersonSignatureStatusTypes.ERROR_ANTI_FRAUD_FACE_MATCH:
            setSubtitle(t('contract.waitingStep.waitNewLiberation.subtitleFaceMatch'));
            break;
          case PersonSignatureStatusTypes.ERROR_ANTI_FRAUD_LIVENESS:
            setSubtitle(t('contract.waitingStep.waitNewLiberation.subtitleLiveness'));
            break;
          case PersonSignatureStatusTypes.SENT_FOR_SIGNATURE:
            if (response.canRetry) {
              history.push(`/assinar/${signatureKey}`);
            }
            break;
          default:
            break;
        }
      })
      .catch(_ => history.push(RoutePath.SUBSCRIPTION_EXPIRED));
  }, []);

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.waitNewLiberation.title')}</h1>
        <span className="subtitle">{subtitle}</span>
        <WaitRegistrationValidationSvg />
        <span>{t('contract.waitingStep.waitNewLiberation.doubts')}</span>
        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(WaitNewLiberation);
