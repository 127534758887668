import { ErrorMessage } from 'features/admin/clientData/components/analisysModal/styles';
import UploadImg from 'images/upload.svg';
import { Attachment } from 'model/attachment';
import { UploadedFileType } from 'model/uploaded-file';
import { useRegisterForm } from 'provider/register-form';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, Label } from './styles';
import Compressor from 'compressorjs';
import { isSafari } from 'react-device-detect';
import { isDeviceIOS } from 'shared/util/utils';

interface Props {
  title?: string;
  titleDocument: string;
  editDocument?: boolean;
  nameInput?: string;
  name?: string;
  type?: string;
  docUrl?: string;
  files?: Attachment[];
  setFiles?: () => void;
  requiredError?: boolean;
}
export default function ContainerDocumentMobile({
  title,
  titleDocument,
  editDocument,
  nameInput,
  name,
  type,
  docUrl,
  requiredError,
}: Props) {
  const { t } = useTranslation();
  const [filename, setFilename] = useState<string>();
  const [newFileName, setNewFileName] = useState<string>('');
  const [url, setUrl] = useState<string>();
  const [subtitle, setSubTitle] = useState(title);
  const { setFiles, files } = useRegisterForm();
  const [invalidDocumentFormat, setInvalidDocumentFormat] = useState<string>();

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleRegister = register(name ?? '');

  const SliceFilename = (file: string) => {
    const splitFile = file.split('.');
    const len = splitFile.length;
    const extension = splitFile[len - 1];
    return `${splitFile[0].split('').slice(0, 6).join('')}...${extension}`;
  };

  const onHandleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files?.[0]?.name) {
      return;
    }
    if (e.target.files) {
      let newFile = e.target.files[0].name;
      if (newFile.split('.')[0].length > 45) {
        newFile = SliceFilename(newFile);
      }
      setFilename(e.target.files[0].name);
      setSubTitle(newFile);
    }
  };

  const handleAddFile = (event: any) => {
    let uploadedFile = event[0];
    const uploadedFileTypes = [
      UploadedFileType.PNG,
      UploadedFileType.JPG,
      UploadedFileType.JPEG,
      UploadedFileType.DNG,
      UploadedFileType.HEIF,
      UploadedFileType.HEIC,
      UploadedFileType.HEICs,
      UploadedFileType.PDF,
    ];
    const uploadedFileImageTypes = [UploadedFileType.PNG, UploadedFileType.JPG, UploadedFileType.JPEG];

    if (uploadedFile == null) {
      return;
    }
    if (!uploadedFileTypes.includes(uploadedFile.type)) {
      setInvalidDocumentFormat(uploadedFile.type);
      return;
    } else {
      setInvalidDocumentFormat(undefined);
    }

    if (uploadedFileImageTypes.includes(uploadedFile.type) && !isDeviceIOS() && !isSafari) {
      new Compressor(uploadedFile, {
        quality: 0.6,
        success: compressedResult => {
          uploadedFile = compressedResult;
        },
      });
    }

    const reader = new FileReader();
    onHandleChangeField(event);

    reader.onload = (ev: any) => {
      const existFilenameEquals = files.find(file => file.fileName === uploadedFile.name);
      const renameFile = `${Math.floor(Math.random() * 101)}${uploadedFile.name}`;

      setNewFileName(renameFile);

      setFiles([
        ...files,
        {
          fileName: existFilenameEquals ? renameFile : uploadedFile.name,
          contentType: uploadedFile.type,
          file: ev.target.result.split(',')[1],
          type: type ?? '',
        },
      ]);
    };
    reader.readAsDataURL(uploadedFile);
  };

  useEffect(() => {
    setSubTitle(title);
    setFilename(title);
  }, [title]);

  useEffect(() => {
    if (newFileName !== '') {
      setFilename(newFileName);
    }
  }, [newFileName]);

  useEffect(() => {
    if (docUrl) {
      setUrl(docUrl);
    }
  }, [docUrl]);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  return (
    <>
      <Container onClick={handleButtonClick} $required={requiredError}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
          <img src={UploadImg} width="20" />
        </div>
        <Label>{t('global.button.mobileAttach')}</Label>
        <input
          type="file"
          id={nameInput}
          style={{ display: 'none' }}
          {...register(name ?? '')}
          ref={inputFileRef}
          accept="image/x-png, image/jpg, image/jpeg, .pdf"
          onChange={e => {
            handleAddFile(e.target.files);
            handleRegister.onChange(e);
          }}
        />
        <span className="subtitle">{t('documents.container.maxSize')}</span>
        <span className="subtitle">{t('documents.container.formats')}</span>
      </Container>
      {requiredError && <ErrorMessage>{t('global.errorMessage.required')}</ErrorMessage>}
      {invalidDocumentFormat && (
        <ErrorMessage>{`${invalidDocumentFormat}: ${t('global.errorMessage.invalidDocumentFormat')}`}</ErrorMessage>
      )}
    </>
  );
}
