import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiPlus } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  PageContainer,
  TabContainer,
  TabContent,
  Title,
  TitleHeader,
  InputBox,
  ContentTitle,
  ButtonContainer,
  Separator,
  PixRegisterContainer,
  StyledSwitch,
  AddNewPixButton,
} from './styles';
import InputText from 'components/styled-input';
import { Mask } from 'model/mask-types';
import { FormProvider, useForm } from 'react-hook-form';
import { CheckBox } from '../../dashboard/screens/report/components/checkbox';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledButton } from 'components/custom-button/styles';
import { TextInput } from 'components/text-input';
import { SearchSelectInput } from 'components/search-selected-input';
import { useSelectLists } from 'provider/select-list';
import { useEditForm } from 'provider/edit-form';
import { City, State } from 'model/address';
import { BankAccountType } from 'model/enums/bank-account-type';
import { Bank, IPix } from 'model/bank-account';
import bankService from 'services/bank-service';
import { PaymentCompany, Subsidiary } from 'model/subsidiaries';
import { SubsidiariesService } from 'services/subsidiaries-service';
import { PixType } from 'model/enums/payment-type';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import StringUtils from 'shared/util/string-utils';
import PixComponent from '../components/PixComponent';

interface Schema {
  name: string;
  city: string;
  state: string;
  cnpj: string;
  partnerCode: string;
  paymentCompany: string;
  bankAccountNumber: string;
  bankAccountDigit: string;
  agencyNumber: string;
  agencyDigit: string;
  bankType: string;
  pixType: string;
  pixKey: string;
  street: string;
  number: string;
  zipcode: string;
  complement: string;
  referencePoint: string;
  district: string;
  assignmentFee: string;
  retentionPercentage: string;
  phone: string;
  email: string;
}

const bankAccountTypesOptions = [
  { label: BankAccountType.CHECKING_ACCOUNT, value: 'CHECKING_ACCOUNT' },
  { label: BankAccountType.SALARY_ACCOUNT, value: 'SALARY_ACCOUNT' },
  { label: BankAccountType.SAVING_ACCOUNT, value: 'SAVING_ACCOUNT' },
];

const pixOptions = [
  { label: 'CPF', value: PixType.CPF },
  { label: 'CNPJ', value: PixType.CNPJ },
  { label: 'E-mail', value: PixType.EMAIL },
  { label: 'Número de telefone', value: PixType.PHONE },
  { label: 'Chave aleatória', value: PixType.RANDOM_KEY },
];

export const CreateBranchScreen = () => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();

  const { t } = useTranslation();
  const { getCepData, initialClientData } = useEditForm();
  const { states, cities, stateID, handleSetStateID, getStates, getCities, statesTotalPages, citiesTotalPages } = useSelectLists();
  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [jointAccount, setJointAccount] = useState<boolean>(false);
  const [onlyScrowAccount, setonlyScrowAccount] = useState<boolean>(false);
  const [bankName, setBankName] = useState('');
  const [bankNames, setBankNames] = useState<Bank[]>([]);
  const [bankPage, setBankPage] = useState(0);
  const [chosenBankId, setChosenBankId] = useState<number>();
  const [banksTotalPages, setBanksTotalPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPixes, setShowPixes] = useState<boolean>(false);
  const [paymentCompanyOptions, setPaymentCompanyOptions] = useState<PaymentCompany[]>([]);
  const [pixesArray, setPixesArray] = useState<IPix[]>([{}]);
  const [pixTypeChosen, setPixTypeChosen] = useState<string>();

  useEffect(() => {
    SubsidiariesService()
      .getPaymentCompanies()
      .then(res => {
        setPaymentCompanyOptions(res);
      });
  }, []);

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7, ac: '' }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage, statePage]);

  useEffect(() => {
    getBankNames(bankName);
  }, [bankPage]);

  const createNewBranch = async (values: Schema) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const city = {
      name: values.city,
      id: cities.find(city => city.name === values.city)?.id,
    } as City;

    const state = {
      name: values.state,
      id: states.find(state => state.name === values.state)?.id,
      acronym: states.find(state => state.name === values.state)?.acronym,
    } as State;

    let newBranch: Subsidiary = {
      name: values.name,
      cnpj: values.cnpj,
      partnerCode: values.partnerCode,
      paymentCompany: paymentCompanyOptions.find(item => item.name === values.paymentCompany),
      assignmentFee: StringUtils.removeMoneyFormat(values.assignmentFee),
      phone: values.phone,
      email: values.email,
      retentionPercentage: parseFloat(values.retentionPercentage),
      onlyRegisteredPixAllowed: showPixes,
      onlyScrowAccount: onlyScrowAccount,
      pixRegistrations: pixesArray.filter(obj => {
        return Object.keys(obj).length > 1 && !Object.values(obj).every(value => value == undefined);
      }),
      bankAccount: {
        bankAccountNumber: values.bankAccountNumber,
        bankAccountDigit: values.bankAccountDigit,
        agencyNumber: values.agencyNumber,
        agencyDigit: values.agencyDigit,
        jointAccount: jointAccount,
        type: bankAccountTypesOptions.find(item => item.label === values.bankType)?.value,
        bank: {
          id: chosenBankId,
        },
        pixType: pixOptions.find(item => item.label === values.pixType)?.value,
        pixKey: values.pixKey,
      },
      address: {
        street: values.street,
        number: values.number,
        zipcode: values.zipcode,
        complement: values.complement,
        referencePoint: values.referencePoint,
        district: values.district,
        uf: state.acronym,
        city,
      },
    };

    await SubsidiariesService()
      .createSubsidiaries(newBranch)
      .then(res => {
        history.push('/admin/filial');
        setLoading(false);
      });
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    cnpj: yup.string().required(t('global.errorMessage.required')),
    assignmentFee: yup.string().required(t('global.errorMessage.required')),
    phone: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().required(t('global.errorMessage.required')),
    partnerCode: yup.string(),
    paymentCompany: yup.string(),
    retentionPercentage: yup.string(),
    zipcode: yup.string().required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup.string().required(t('global.errorMessage.required')),
    complement: yup.string(),
    referencePoint: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    bankAccountNumber: yup.string().required(t('global.errorMessage.required')),
    bankAccountDigit: yup.string().required(t('global.errorMessage.required')),
    agencyNumber: yup.string().required(t('global.errorMessage.required')),
    agencyDigit: yup.string(),
    bankType: yup.string().required(t('global.errorMessage.required')),
    pixType: yup.string(),
    pixKey: yup.string(),
    city: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
  });

  const methods = useForm<any>({ resolver: yupResolver(schema) });

  const getBankNames = (searchTextSendApiBankNames = '') => {
    bankService.getAllBankNames(bankPage, searchTextSendApiBankNames, 'iugu').then(response => {
      setBanksTotalPages(response.data.totalPages);
      if (bankPage > 0) {
        setBankNames([...bankNames, ...response.data.content]);
      } else {
        setBankNames(response.data.content);
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPixes(event.target.checked);
  };

  return (
    <>
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />
        <TitleHeader>
          <Title>
            <div className="firstTitle">
              <button
                onClick={() => {
                  if (location.state?.prevPath) {
                    history.push(location.state.prevPath);
                  } else {
                    history.push('/admin/filial');
                  }
                }}
              >
                <FiChevronLeft color={color.primaryColor} />
              </button>
              <span className="title">{t('admin.dashboard.branchRegister')}</span>
            </div>
          </Title>
        </TitleHeader>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(formData => createNewBranch(formData))}>
            <TabContainer>
              <TabContent>
                <ContentTitle>{t('stepper.title.general_data')}</ContentTitle>
                <InputBox>
                  <InputText name="name" label={t('register.inputs.branchName')} placeholder={t('register.placeholder.insertBranchName')} />

                  <InputText
                    name="cnpj"
                    label={t('register.inputs.cnpj')}
                    placeholder={t('register.placeholder.cnpj')}
                    mask={Mask.CNPJ}
                    maxLength={19}
                  />

                  <InputText
                    name="phone"
                    label={t('register.inputs.phone')}
                    placeholder={t('register.placeholder.phone')}
                    mask={Mask.PHONE}
                    maxLength={15}
                  />
                  <InputText
                    name="email"
                    label={t('register.inputs.email')}
                    mask={Mask.EMAIL}
                    placeholder={t('register.placeholder.email')}
                  />

                  <InputText
                    name="partnerCode"
                    label={t('register.inputs.partnerCode')}
                    placeholder={t('register.placeholder.insertPartnerCode')}
                    maxLength={15}
                  />
                </InputBox>
              </TabContent>

              <Separator />

              <TabContent>
                <ContentTitle>{t('stepper.title.address')}</ContentTitle>
                <InputBox>
                  <InputText
                    name="zipcode"
                    mask={Mask.CEP}
                    onChange={e => {
                      if (e.target.value.length === 9) {
                        getCepData(e.target.value);
                      }
                    }}
                    label={t('register.inputs.cep')}
                    placeholder={'00000-000'}
                  />
                  <InputText
                    name="street"
                    label={t('stepper.title.address')}
                    defaultValue={initialClientData?.address?.street ?? ''}
                    placeholder={t('stepper.title.address')}
                  />
                  <InputText
                    name="number"
                    label={t('register.inputs.number')}
                    defaultValue={initialClientData?.address?.number ?? ''}
                    placeholder={'000'}
                    maxLength={5}
                  />
                  <InputText
                    name="complement"
                    label={t('register.inputs.complement')}
                    placeholder={t('register.placeholder.complement')}
                    maxLength={15}
                  />
                  <InputText
                    name="referencePoint"
                    label={t('register.inputs.reference')}
                    placeholder={t('register.placeholder.reference')}
                  />
                  <InputText
                    name="district"
                    label={t('register.inputs.district')}
                    defaultValue={initialClientData?.address?.district ?? ''}
                    placeholder={t('register.placeholder.district')}
                    maxLength={15}
                  />
                  <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="state"
                      defaultValue={initialClientData?.address?.city?.state?.name}
                      placeholder={t('register.placeholder.state')}
                      options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
                      defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
                      handleChange={values => {
                        const state = states.find(st => st.name === values.name);
                        if (state != null) {
                          handleSetStateID(state.id);
                        }
                        setStateName(values.name);
                        setStatePage(0);
                        getStates({ name: values.name, size: 7 });
                      }}
                      handleOnScroll={() => {
                        if (statePage < statesTotalPages - 1) {
                          setStatePage(statePage + 1);
                        }
                      }}
                    />
                  </TextInput>
                  <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="city"
                      defaultValue={initialClientData?.address?.city?.name}
                      placeholder={t('register.placeholder.city')}
                      options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
                      defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
                      handleChange={values => {
                        setCityName(values.name);
                        setCityPage(0);
                        if (stateID) {
                          getCities(stateID, { name: values.name, size: 7 });
                        }
                      }}
                      handleOnScroll={() => {
                        if (cityPage < citiesTotalPages - 1) {
                          setCityPage(cityPage + 1);
                        }
                      }}
                    />
                  </TextInput>
                </InputBox>
              </TabContent>

              <Separator />

              <TabContent>
                <ContentTitle>{t('stepper.title.bank_account_data')}</ContentTitle>
                <InputBox>
                  <InputText
                    disabled
                    name="name"
                    label={t('register.inputs.name')}
                    placeholder={t('register.placeholder.insertCompleteName')}
                  />
                  <InputText
                    name="cnpj"
                    disabled
                    label={t('register.inputs.cpfcnpj')}
                    placeholder={t('register.placeholder.cnpj')}
                    mask={Mask.CNPJ}
                  />
                  <TextInput name="bank" label={t('register.inputs.bank')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="bank"
                      placeholder={t('register.placeholder.selectBank')}
                      options={bankNames.map(bank => ({ label: bank.name ?? '', value: bank?.id?.toString() ?? '' }))}
                      defaultOptions={bankNames.map(bank => ({ label: bank.name ?? '', value: bank?.id?.toString() ?? '' }))}
                      handleChange={values => {
                        setBankName(values.name);
                        setBankPage(0);
                        getBankNames(values.name);

                        if (values.value) {
                          setChosenBankId(parseInt(values.value));
                        }
                      }}
                      handleOnScroll={() => {
                        if (bankPage < banksTotalPages) {
                          setBankPage(bankPage + 1);
                        }
                      }}
                    />
                  </TextInput>
                  <TextInput name="bankType" label={t('register.inputs.accountType')} isRequired hasCustomInput>
                    <SearchSelectInput
                      name="bankType"
                      placeholder={t('register.placeholder.selectBankType')}
                      options={bankAccountTypesOptions}
                      defaultOptions={bankAccountTypesOptions}
                    />
                  </TextInput>
                  <InputText
                    name="agencyNumber"
                    label={t('register.inputs.agency')}
                    placeholder={t('register.placeholder.account')}
                    mask={Mask.NUMBERS}
                    maxLength={7}
                    width={220}
                    flexWidth
                  />
                  <InputText
                    name="agencyDigit"
                    label={t('register.inputs.digit')}
                    placeholder={t('register.placeholder.digit')}
                    mask={Mask.NUMBERS}
                    maxLength={3}
                    width={68}
                    flexWidth
                  />
                  <InputText
                    name="bankAccountNumber"
                    label={t('register.inputs.account')}
                    placeholder={t('register.placeholder.account')}
                    mask={Mask.NUMBERS}
                    maxLength={7}
                    width={220}
                    flexWidth
                  />
                  <InputText
                    name="bankAccountDigit"
                    label={t('register.inputs.digit')}
                    placeholder={t('register.placeholder.digit')}
                    mask={Mask.NUMBERS}
                    maxLength={3}
                    width={68}
                    flexWidth
                  />
                  <div style={{ placeSelf: 'self-start' }}>
                    <TextInput name="" label={t('register.inputs.isJoinAccount')} hasCustomInput>
                      <CheckBox text="" checked={jointAccount} handleClick={() => setJointAccount(!jointAccount)} />
                    </TextInput>
                  </div>
                </InputBox>
              </TabContent>

              {(ORGANIZATION_NAME === OrganizationName.EVOLVE || ORGANIZATION_NAME === OrganizationName.MULTTIPLO) && (
                <>
                  <Separator />

                  <TabContent>
                    <ContentTitle>{t('stepper.title.add_infos')}</ContentTitle>
                    {ORGANIZATION_NAME === OrganizationName.MULTTIPLO && (
                      <>
                        <InputBox>
                          <InputText
                            name="assignmentFee"
                            mask={Mask.PERCENTAGE}
                            label={t('register.inputs.sessionTaxes')}
                            placeholder={'0,00%'}
                          />

                          <TextInput name="paymentCompany" label={t('register.inputs.depositCompany')} isRequired hasCustomInput>
                            <SearchSelectInput
                              name="paymentCompany"
                              disabled={paymentCompanyOptions.length === 0}
                              placeholder={t('register.placeholder.selectDepositCompany')}
                              options={paymentCompanyOptions.map(item => ({ label: item.name ?? '', value: item?.id?.toString() ?? '' }))}
                              defaultOptions={paymentCompanyOptions.map(item => ({
                                label: item.name ?? '',
                                value: item?.id?.toString() ?? '',
                              }))}
                            />
                          </TextInput>
                          <div style={{ placeSelf: 'self-start' }}>
                            <TextInput name="" label={t('register.inputs.onlyScrowPayment')} hasCustomInput>
                              <CheckBox text="" checked={onlyScrowAccount} handleClick={() => setonlyScrowAccount(!onlyScrowAccount)} />
                            </TextInput>
                          </div>
                        </InputBox>
                      </>
                    )}
                    {ORGANIZATION_NAME === OrganizationName.EVOLVE && (
                      <InputBox>
                        <InputText
                          name="retentionPercentage"
                          mask={Mask.PERCENTAGE}
                          label={t('register.inputs.retentionTaxes')}
                          placeholder={'0,00%'}
                        />
                      </InputBox>
                    )}
                  </TabContent>
                </>
              )}
              <PixRegisterContainer>
                <h1>{t('register.inputs.pixRegister')}</h1>
                <span>{t('register.inputs.pixRegisterSubtitle')}</span>
                <span>{t('register.inputs.activePixRegister')}</span>
                <StyledSwitch onChange={handleChange} />
                {showPixes && (
                  <>
                    {pixesArray.map((item, idx) => {
                      return (
                        <PixComponent
                          key={`pixComponent-${idx}`}
                          defaultPix={item}
                          index={idx}
                          pixesArray={pixesArray}
                          setPixesArray={setPixesArray}
                        />
                      );
                    })}
                    <AddNewPixButton type="button" onClick={() => setPixesArray([...pixesArray, { pixKey: '' }])}>
                      <FiPlus size={22} color={color.primaryColor} />
                      <span>{t('register.inputs.addPixKey')}</span>
                    </AddNewPixButton>
                  </>
                )}
              </PixRegisterContainer>
            </TabContainer>
            <ButtonContainer>
              <StyledButton disabled={loading}>{t('admin.dashboard.branchButton')}</StyledButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </PageContainer>
    </>
  );
};
