import networkDataApi from 'api/network-data-api';
import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IInternetProtocol } from 'model/network-data';
import IPersonSignatureLocationData from 'model/person-singature-location';

export const networkDataService = () => {
  const getPublicApiInternetProtocol = async () => {
    try {
      const publicApiResult: AxiosResponse<string> = await networkDataApi.getPublicApiInternetProtocol();
      if (publicApiResult.status === 200 && publicApiResult.data != null) {
        return Promise.resolve(publicApiResult.data);
      }

      return Promise.reject({ status: publicApiResult.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getInternetProtocol = async () => {
    try {
      const result: AxiosResponse<IInternetProtocol> = await networkDataApi.getInternetProtocol();
      if (result.status === 200 && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const sendLocationData = async (data: IPersonSignatureLocationData): Promise<IPersonSignatureLocationData> => {
    try {
      const result: AxiosResponse<IPersonSignatureLocationData> = await networkDataApi.postLocalizationAndProtocol(data);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    getPublicApiInternetProtocol,
    getInternetProtocol,
    sendLocationData,
  };
};

export default networkDataService();
