import CustomButton from 'components/custom-button';
import Status from 'components/status/Status';
import { ReactComponent as PlusSvg } from 'images/white-plus.svg';
import { Financing } from 'model/financing';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import {
  StyleCardInnerContainer,
  StyledCardContainer,
  StyledCardsContainerMobile,
  StyledCardText,
  StyledCardTitle,
  StyledFooterMobile,
  StyledHeaderCardContainer,
  StyledInnerContainer,
  StyledNoDataContainer,
  StyledTitle,
} from '../styles';
import { ReactComponent as DollarSignSvg } from 'images/dollar-sign.svg';

interface Props extends WithTranslation {
  myProcedures?: Financing[];
}

const Mobile = ({ myProcedures, t }: Props) => {
  const history = useHistory();
  const { color } = useTheme();

  return (
    <>
      <StyledInnerContainer>
        <StyledTitle style={{ marginBottom: '16px' }}>{t('myProcedures.title')}</StyledTitle>
        {myProcedures != null && myProcedures.length > 0 ? (
          <StyledCardsContainerMobile>
            {myProcedures.map((procedure, index) => {
              const formattedValue =
                procedure?.choosedValue != null && procedure?.approvedValue != null
                  ? `R$ ${StringUtils.maskAmount(
                      procedure.choosedValue > procedure?.approvedValue ? procedure?.approvedValue : procedure.choosedValue
                    )}`
                  : `R$ ${StringUtils.maskAmount(procedure?.choosedValue)}`;

              return (
                <StyledCardContainer
                  key={`procedure-${index}`}
                  onClick={() =>
                    history.push('/procedimentos/dashboard', {
                      procedureId: procedure.id,
                      procedureStatus: procedure.financingStatus,
                      choosedValue: formattedValue,
                      medicalProcedure: procedure.medicalProcedure,
                      consultantComment: procedure.consultantComment,
                    })
                  }
                >
                  <StyleCardInnerContainer>
                    <StyledHeaderCardContainer>
                      <StyledCardTitle>{procedure.identification}</StyledCardTitle>
                      {procedure?.financingStatus != null ? (
                        <Status
                          status={StringUtils.configureCreditTableStatusView(
                            procedure.financingStatus,
                            procedure.contracts?.[0]?.status,
                            procedure.payment?.status
                          )}
                          style={{ fontSize: '12px' }}
                        />
                      ) : (
                        <></>
                      )}
                    </StyledHeaderCardContainer>
                    <StyledCardText>
                      {`${formatDateToLocalFormat(procedure.createdDate)} | `}
                      {formattedValue}
                    </StyledCardText>
                  </StyleCardInnerContainer>
                  <FiChevronRight color={color.primaryColor} size={24} />
                </StyledCardContainer>
              );
            })}
          </StyledCardsContainerMobile>
        ) : (
          <StyledNoDataContainer>
            <DollarSignSvg />
            {t('myProcedures.noProductsYet')}
          </StyledNoDataContainer>
        )}
      </StyledInnerContainer>

      <StyledFooterMobile>
        <CustomButton height={48} width="258px" onClick={() => history.push('/procedimentos/novo-procedimento')}>
          <PlusSvg />
          {t('global.button.addProcedure')}
        </CustomButton>
      </StyledFooterMobile>
    </>
  );
};

export default withTranslation()(Mobile);
