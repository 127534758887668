import DocumentInstructionFrame from 'images/document-instruction-frame.png';
import CngPng from 'images/cnh.png';
import MobileHeader from 'components/mobile-header';
import CustomButton from 'components/custom-button';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import { ContractList, PageTitleSection, StyledCardShowContractContainer, StyledContainer, StyledInnerContainer } from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import { Trans } from 'react-i18next';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import { ReactComponent as CameraIconSvg } from 'images/camera.svg';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { RoutePath } from 'model/enums/route-path';
import { IdentityDocumentTypes } from 'model/enums/identity-document-type';

const DocumentInstructions = ({ t }: WithTranslation) => {
  const history = useHistory();

  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const { isMobile, isTablet } = useResponsiveMobile();

  const { documentPresignUrl, signatureKey, identityDocument } = useContext(ContractContext);

  const takeDocument = () => {
    history.push(RoutePath.TAKE_PHOTO_DOCUMENT);
  };

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }

    if (!signatureKey) {
      return history.push('/');
    }
  }, []);

  return (
    <StyledContainer>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(`/lets-sign`)} />
            <span>{t('letsSign.stepOfTotal', { step: '3' })}</span>
          </ArrowContainer>
          <p>
            {identityDocument === IdentityDocumentTypes.RG || identityDocument === IdentityDocumentTypes.OTHER
              ? t('contract.documentInstructions.titleRg')
              : t('contract.documentInstructions.titleCnh')}
          </p>
          <span>
            {identityDocument === IdentityDocumentTypes.RG || identityDocument === IdentityDocumentTypes.OTHER
              ? t('contract.documentInstructions.subtitleRg')
              : t('contract.documentInstructions.subtitleCnh')}
          </span>
        </PageTitleSection>
        {identityDocument === IdentityDocumentTypes.RG || identityDocument === IdentityDocumentTypes.OTHER ? (
          <img style={{ width: '100%', maxWidth: '500px' }} src={DocumentInstructionFrame} alt="document-frame" />
        ) : (
          <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <img src={CngPng} alt="cnh" />
          </div>
        )}
        <ContractList>
          <li>{t('contract.documentInstructions.contractList.removeDocumentFromPlastic')}</li>
          {identityDocument === IdentityDocumentTypes.CNH && (
            <li>{t('contract.documentInstructions.contractList.sendPhotoOpenedDriverLicense')}</li>
          )}
          <li>{t('contract.documentInstructions.contractList.avoidTakingPhotoFlash')}</li>
          <li>{t('contract.documentInstructions.contractList.takeFrontAndBackPhotos')}</li>
          <li>
            <Trans i18nKey="contract.documentInstructions.contractList.checkInformationIsVisibleAndNoLightOrShadow" />
          </li>
        </ContractList>
        <CustomButton className="mobile-button--gofoward" disabled={false} onClick={() => takeDocument()}>
          <CameraIconSvg style={{ marginRight: '8px' }} />
          {t('global.button.takeDocumentPic')}
        </CustomButton>
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(DocumentInstructions);
