import { StyledInnerContainer } from './styles';
import { WaitRegistrationValidationSvg } from 'images/wait-registration-validation';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { useTheme } from 'styled-components';
import ReactLoading from 'react-loading';
import { LoadingIcon } from 'images/loadingIcon/loadingIcon';

const SelfieAnalysisFromLiveness = ({ t }: WithTranslation) => {
  const { color } = useTheme();

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.selfieAnalysisFromLiveness.title')}</h1>
        <span>{t('contract.waitingStep.selfieAnalysisFromLiveness.subtitle')}</span>
        <div className="animationAndSvg">
          <WaitRegistrationValidationSvg />
          <LoadingIcon className="spin" />
        </div>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(SelfieAnalysisFromLiveness);
