import { StyledInnerContainer, CentralizedContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { ReactComponent as TroubleAlertSvg } from 'images/orange-trouble-alert.svg';

const ErrorAnalysis = ({ t }: WithTranslation) => {
  return (
    <>
      <MobileHeader />
      <StyledInnerContainer>
        <CentralizedContainer>
          <TroubleAlertSvg style={{ marginBottom: '46px' }} />
          <h1>{t('contract.waitingStep.errorAnalysis.title')}</h1>
          <span>{t('contract.waitingStep.errorAnalysis.subtitle')}</span>
        </CentralizedContainer>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(ErrorAnalysis);
